
// -- Sidemenu LTR styles --//
.closed-menu {
  
@media (min-width: 768px) {
  &.app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }

    .app-sidebar {
      left: 250px;
    }

    .app-sidebar__overlay {
      visibility: hidden;
    }
  }

  .app-sidebar__user {
    margin-top: 74px;
  }
}
@media (max-width: 767px) {
  .side-menu{
    margin-top: 0px !important;
  }
   &.app {
  overflow-x: hidden;
    .app-sidebar {
    left: -265px;
    margin-top: 74px !important;
    }
    .app-sidebar__overlay {
      visibility: hidden;
    }

    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }

      .app-sidebar {
        left: 0;
      }

      .app-sidebar__overlay {
        visibility: visible;
      }
    }
     
  }  

  &.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    /* width: 2.5rem !important; */
    line-height: 1;
  }}
 

.app-sidebar .ps__thumb-y {
  right: 0;
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  margin-bottom: 0 !important;
  -webkit-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  overflow: hidden;

  .side-app {
    padding: 25px 1.5rem 0 1.5rem;
  }
}

.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 250px;
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }
}


@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999999;
  box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
  transition: padding-left 0.3s ease;
  border-bottom: 1px solid #e6ebf1 !important;
}

@media (min-width: 768px) {
  .app-header {
    padding-left: 250px;
    padding-bottom:0px;
  }

  &.sidebar-mini.sidenav-toggled .app-header {
    padding-left: 0px;
  }
}

@media print {
  .app-header {
    display: none;
  }
}

.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: 'Niconne';
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;

  &:focus, &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 250px;
  }
}

.app-sidebar__toggle {
  a {
    color: #b6c1d9;
  }

  .header-icon {
    line-height: 42px;
    text-align: center;
    vertical-align: middle;
    width: 38px;
    height: 38px;
    padding: 8px;
    margin-top: 3px;
  }

  	padding: .85rem 1rem;
      font-size: 1.2rem;
      border-right: 1px solid #e6ebf1;
      position: relative;
    margin-left: -12px;
   &:focus, &:hover {
    text-decoration: none;
  }
}



@media (max-width: 767px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: flex-right;
}

@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}

.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
  }
}

.app-search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

@media (max-width: 480px) {
  .app-search {
    display: none;
  }
}

.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

.app-notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;

  &:focus, &:hover {
    color: inherit;
    text-decoration: none;
    background-color: #e6ebf1;
  }
}

.app-notification__message, .app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: 0px;
  width: 250px;
  max-height: 100%;
  z-index: 9999999;
  background: $white !important;
  border-right: 1px solid #e6ebf1;
   box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
   -webkit-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}

.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: $color;
  width: 100%;
  padding: 20px;
  display: inline-block;
  border-bottom: 1px solid $border;
}

&.sidenav-toggled .app-sidebar__user .user-pic {
  margin: 0px 0px 0px 0;
}

.app-sidebar__user .user-pic {
  margin-bottom: 12px;
}

.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  &.text-sm {
    font-size: 12px;
    font-weight: 400;
  }

  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px !important;
}

.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  font-size: 15px;
  margin-top: 5px !important;
}

.side-menu {
  margin-bottom: 0;
  padding-bottom: 40px;
  margin-top: 90px; 
}

.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3px 22px 3px 5px;
  font-size: 20px;
  font-weight: 400;
  color: $color;
  border-radius: 0.25rem;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 3px;

  &.active, &:hover, &:focus {
    text-decoration: none;
    background: #F3F6F8;
    color: $color;
    .side-menu__icon{
      fill: $primary !important;
    }
   }
   .fe-chevron-right {
     font-size: 0.95rem;
   }
} 
&.sidenav-toggled{
  .side-menu__item:focus {
    background: transparent !important;
    .side-menu__icon {
      fill: #6c756e;
    }
  }
}
&.sidenav-toggled1{
  .side-menu__item:focus {
    background: #F3F6F8 !important;
    .side-menu__icon {
      fill: #fff;
    }
  }
}
@media (min-width: 768px) {
  &.app.sidebar-mini.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    min-width: 100%;
    margin: 0;
    left: 0;
    opacity: 1;
    background: transparent;
    font-size: 14px;
    box-shadow: none;
  }
}

.slide-item {
  &.active, &:hover, &:focus {
    text-decoration: none;
  }
}

.slide.is-expanded a.slide-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    margin-left: 5px;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.side-menu .side-menu__icon {
  font-size: 1.24rem;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  padding: 8px;
 margin-right: 0.5rem;
 
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .6px;
  opacity: 1;
  height: auto;
  transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.slide.is-expanded {
  .slide-menu {
    display: block;
  }

  .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.slide-menu {
  display: none;
  padding: 0;
  font-size: .8rem !important;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 14px 7px 44px;
  font-size: 13px;
  color: #6c756e;

  .icon {
    margin-right: 5px;
  }
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  font-size:12px;
}

@media (min-width: 768px) {
  &.sidebar-mini.sidenav-toggled {
    .app-sidebar__user-name, .app-sidebar__user-designation, .angle, .app-sidebar__user-name, .user-notification, .app-sidebar__user-name, .user-info {
      display: none;
    }

    .sidebar-mini.sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }

    .avatar-xl {
      width: 3rem !important;
      height: 3rem !important;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
    }

    .sidebar-navs {
      display: none;
    }

    .app-sidebar__user {
      top: .5rem;
      right: 30px !important;
      margin-top: 74px;
    }

    top: .5rem;
    right: 30px !important;

    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }

    .side-menu li .side-menu__item.active:before {
      display: none;
    }

    .app-sidebar__user {
      padding: 4px 0px 0px 0;
      margin-bottom: 0px;
    }

    .profile-img {
      top: 0px;
      right: 19px;
    }

    .app-content {
      margin-left: 0px;
    }

    .app-sidebar {
      left: -250px;
      width: 71px;
      overflow: hidden;
      -webkit-transition: left 0.3s ease, width 0.3s ease;
      transition: left 0.3s ease, width 0.3s ease; 
	     max-height:100%;
    }

    &.sidenav-toggled1 .app-sidebar {
      overflow: visible;
    }

    .side-menu__item {
      overflow: hidden;

      &:hover {
        overflow: visible;

        .side-menu__label {
          opacity: 1;
        }

        + .slide-menu {
          visibility: visible;
        }
      }
    }

    .side-menu__label {
      display: block;
      position: absolute;
      top: 0;
      left: 50px;
      min-width: 180px;
      padding: 12px 5px 12px 20px;
      margin-left: -3px;
      line-height: 1;
      opacity: 0;
      background: $white;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }

    .slide {
      &:hover {
        .side-menu__label {
          opacity: 1;
        }

        .slide-menu {
          max-height: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
      }

      .side-menu__label {
        border-bottom-right-radius: 0;
      }
    }

    .slide-menu {
      position: absolute;
	    left:0;
      min-width: 180px;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
      -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    }
  }

  &.app.sidebar-mini.sidenav-toggled {
    .side-menu__item {
      display: block;
      padding: 10px 0;
      margin: 0 auto;
      text-align: center;
      border-left: 0;
      border-radius: 0;
    }

    .side-menu_label {
      display: block;
      font-size: 12px;
    }

    .side-menu__label {
      display: block;
      position: relative;
      padding: 0;
      min-width: 100%;
      margin: 0;
      left: 0;
      opacity: 1;
      background: transparent;
      font-size: 14px;
      box-shadow: none;
    }
  }

  &.sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }

  &.app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }

  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 {
    .app-sidebar {
      width: 250px;
      -webkit-transition: left 0.3s ease, width 0.3s ease;
      transition: left 0.3s ease, width 0.3s ease;
	  max-height:100%;

      .app-sidebar__user {
        .dropdown-menu {
          top: 10px !important;
        }

        display: inline-block;
        align-items: center;
        width: 100%;
        padding: 20px;
        display: inline-block;
        border-bottom: 1px solid $border;
      }
    }

    .sidebar-navs {
      display: block;
    }

    .app-sidebar {
      .app-sidebar__user-avatar {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-right: 15px;
        display: inline-block;
      }

      .app-sidebar__user-name {
        &.text-sm {
          font-size: 12px;
          font-weight: 400;
          display: inline-block;
          width: 100%;
          height: auto;
          margin: 0 !important;
        }

        font-size: 17px;
        display: inline-block;
        line-height: 1.3;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        margin-bottom: 0;
        overflow: hidden;
        font-weight: 600;
        color: #e5e9ec;
        font-size: 15px;
        margin-top: 5px !important;
        display: inline-block;
      }
    }
  }

  .app-sidebar__user-designation {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
    display: inline-block;
  }

  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    .side-menu {
      margin-bottom: 0;
      padding-bottom: 40px;
    }

    .side-menu__item {
      position: relative;
      display: flex !important;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      line-height: 1.40625rem;
      vertical-align: middle;
      font-size: 20px;
      font-weight: 400;
      -webkit-transition: border-start-color 0.3s ease, background-color 0.3s ease;
      -o-transition: border-start-color 0.3s ease, background-color 0.3s ease;
      transition: border-start-color 0.3s ease, background-color 0.3s ease;
      padding:1px 22px 1px 5px;
      border-radius: 0.25rem;
      margin:0px 10px 3px 10px;
    }

    .slide-item.active {
      text-decoration: none;
      color: $color;
    }
  }

  .slide-item {
    &:hover, &:focus {
      text-decoration: none;
      color: #b48ef4;
    }
  }

  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item.active {
    text-decoration: none;
  }

  .slide-item {
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    .slide.is-expanded a {
      /*color: $color;*/
      text-decoration: none;
    }

    .side-menu .side-menu__icon {
      margin-right: 0px;
    }

    .side-menu__label {
      display: inline-flex !important;
      font-size: 14px;
      position: unset;
      min-width: inherit;
    }

    .side-menu__icon {
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      margin-right: 7px;
      width: 36px;
      height: 36px;
      background: transparent;
      padding: 8px !important;
      margin-right: 0.5rem;
       }

    .side-menu__label {
      white-space: nowrap;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      position: unset;
      display: inline-block;
      font-size: 14px;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }

    .slide.is-expanded {
      .slide-menu {
       display: block;
      }

      .angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }

    .angle {
      display: inline-block;
    }

    .slide-menu {
      display: none;
      padding: 0;
      font-size: .8rem !important;
      box-shadow: none;
      position: unset;
      top: inherit;
      min-width: 0;
      width: 100%;
      opacity: inherit;
      visibility: inherit;
    }

    .slide-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 14px 10px 55px;
      font-size: 13px;

      .icon {
        margin-right: 5px;
      }
    }

    .angle {
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      -o-transition: transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      font-size:0.95;
     }

    .app-sidebar__user img {
      margin-top: 8px;
      width: 4rem !important;
      height: 4rem !important;
      line-height: 4rem;
      font-size: 1.75rem;
      margin-bottom: 0.25rem !important;
    }

    .user-info {
      display: inline-block;
    }
  }

  &.sidenav-toggled.sidenav-toggled1 .app-sidebar__user .user-pic {
    margin: 0px 0px 12px 0;
  }
}

&.app.sidebar-mini {
  &.sidenav-toggled .side-menu .side-menu__icon {
    position: relative;
  }

  .side-menu_label {
    display: none;
  }
}

.dropdown-menu {
  border-radius: 0;

  &.dropdown-menu-end {
    left: auto;
  }
}

.dropdown-item {
  .fa, .icon {
    vertical-align: middle;
  }
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: #FFF;
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
    font-style: italic;
  }
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: flex-left;
  }
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

&.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}

.app-sidebar {
  .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: rgba(0, 0, 0, 0.05);
  }

  .mCSB_scrollTools {
    .mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.slide-menu a:before {
  content: "";
  margin-right: 8px;
  position: relative;
  opacity: 0.6;
}

&.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }

  .nav-badge1 {
    display: none;
  }
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}


@media (max-width: 575.98px) {
  .app-header .header-brand-img.mobile-logo {
    margin-left: 0px;
    margin-top: 16px;
  }

  .app-content .side-app {
    padding: 25px 0.75rem 0 0.75rem;
  }

  .app-sidebar {
    margin-top: 74px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .app-header {
    .header-brand-img.desktop-lgo, .header-brand {
      margin-left: 0;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 1.25rem 1.25rem;
  }

  footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 250px;
  }

  &.sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}

@media (max-width: 767px) {
  .app-sidebar__logo {
    display: none;
   }
  
}

@media (min-width: 768px) {
  .app-header .header-brand {
    display: none;
  }

  .header.top-header {
    padding-left: 250px;
  }

  &.sidenav-toggled {
    .header.top-header {
      padding-left: 70px;
    }

    .app-sidebar__logo {
      padding: 11px 12px;
      width: 70px;
      border-right: 0px !important;
    }
    &.sidenav-toggled1 {
      .app-sidebar__logo {
        border-right: 1px solid #e6ebf1 !important;
      }
    }
  }
}

.app-sidebar__logo {
  padding:11px 20px;
  border-bottom: 1px solid #e6ebf1;
  border-right: 1px solid #e6ebf1;
  text-align: center;
  width: 250px;
  z-index: 999999;
  position: fixed;
  background:#fff;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  -o-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
}

.close-toggle {
  display: none;
}

.app-sidebar__toggle a i {
  line-height: 1.5;
  margin-top: 0;
}

@media (min-width: 768px) {
  .header-brand-img {
    &.dark-logo, &.darkmobile-logo, &.mobile-logo {
      display: none;
    }
  }

  &.sidenav-toggled {
    .header-brand-img {
      &.desktop-lgo {
        display: none;
      }

      &.mobile-logo {
        display: flex;
        margin-top: 5px;
        margin: 0 auto;
      }
    }

    &.sidenav-toggled1 .header-brand-img {
      &.mobile-logo {
        display: none;
      }

      &.desktop-lgo {
        display: flex;
        margin: 0 auto;
      }
    }
  }

  .app-sidebar {
    &.app-sidebar3 {
      top: 74px;
    }

    &.app-sidebar2 {
      box-shadow: none;
      z-index: 9999;
      height: 73px;
    }
  }

  &.sidenav-toggled {
    .app-sidebar-help .help-dropdown {
      display: none;
    }

    &.sidenav-toggled1 .app-sidebar-help .help-dropdown {
      display: flex;
    }

    .app-sidebar-help, .app-sidebar .side-item.side-item-category, .side-badge {
      display: none;
    }

    &.sidenav-toggled1 .app-sidebar-help {
      width: 250px;
    }
  }

  &.app.sidebar-mini.sidenav-toggled {
    .side-menu .side-menu__icon {
      margin-right: 0;
    }

    .slide a.side-menu__item.active:after {
      top: 20px;
    }
  }

  &.sidenav-toggled.sidenav-toggled1 {
    .app-sidebar-help, .app-sidebar .side-item.side-item-category, .side-badge {
      display: block;
    }

    .app-sidebar__logo {
      width: 250px;
      border-right: 1px solid #e6ebf1;
    }
  }
}

.app-sidebar.app-sidebar3 {
  padding-bottom: 70px;
}

.app-sidebar-help {
  position: fixed;
  bottom: 0;  
  border-top: 1px solid $border;
  background: $white;
  z-index: 11;
  width: 250px;

  .header-icon {
    width: 30px;
    height: 30px;
  }
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  height: 30px;
  padding: 0 0 0 25px !important;
  padding-left: 70px !important;
  height: 28px !important;
  padding: 19px 0 19px 60px !important;
  font-size: 12px;
  color: #6c756e;
}

.sub-slide-menu {
  display: none;
  padding: 0;
  font-size: .8rem !important;
  padding-left: 0;
  list-style: none;
}

.slide-menu .sub-slide.active.is-expanded {
  background: rgba(255, 255, 255, 0.09);
}

.sub-slide.is-expanded .sub-slide-menu {
  li:last-child {
    padding-bottom: 10px;
  }
  display: block;
}

.sub-side-menu__item {
  padding-left: 44px !important;
  padding-right: 20px !important;
  height: 40px !important;
  display: block;
  line-height: 40px;
  font-size: 13px;
  color:#6c756e;
 }
 .slide a.sub-side-menu__item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    margin-left: 5px;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 12px;
}

.help-dropdown {
  color: $color !important;
}

.slide.is-expanded a.sub-side-menu__item {
  position: relative;
}

.app-sidebar__user .user-pic img {
  box-shadow: 0 0 0 4px #eeeef3;
  border: 3px solid $background;
  width: 58px;
  height: 58px;
  margin-top: 8px;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #8760fb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.sidemenu-footer {
  .icon {
    margin: 5px;
    padding: 7px 14px;
    text-align: center;
    position: relative;
  }

  .header-icons {
    width: 24px;
    height: 24px;
    color: $color;
    fill: $color;
  }
}

.tooltip {
  z-index: 999 !important;
}

.profile-status {
  content: '';
  position: absolute;
  bottom: 0;
  right: 80px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  top: 51px;
  border: 2px solid $white;
}

.app-sidebar .side-item.side-item-category {
  color: #9993b5;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .5px;
  margin:15px 0px 15px 0px;
  padding: 0 20px 0 20px;

  &:not(:first-child) {
    margin-top: 25px;
    margin-bottom: 15px;
  }
}

.app-sidebar__user .user-info .user-name {
  margin-bottom: 2px;
  font-size: 15px;
}

.profile-dropdown {
  .nav-link {
    padding-left: 5px;
  }

  img {
    width: 35px;
    height: 35px;
    box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    border: 2px solid $background;
  }
}

.user-pro-body .dot-label {
  width: 6px;
  height: 6px;
}

.sidebar-navs a {
  background: $white;
  color: #68798b !important;
  
  padding: 1rem !important;
  
}

.app-sidebar .sidebar-navs {
  padding: 10px 0px 0px 0px;
}

.sidebar-navs {
  .nav li:last-child a {
    margin-right: 0 !important;
  }

  .header-icons {
    color: #5c678f;
    fill: #5c678f;
    font-size: 20px;
    vertical-align: middle;
  }
}

.slide.active.is-expanded .side-menu__item {
  text-decoration: none;
  color: $color;
  
  border-radius: 50px;
}

&.app.sidebar-mini.sidenav-toggled .slide.active.is-expanded .side-menu__item {
  border-radius: 0;
}
@media (min-width: 767px) { 
	&.sidebar-mini.sidenav-toggled .app-sidebar{
       max-height:100%;
	}
	&.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar{
       max-height:100%;
	}
}

/*--- sub-slide-menu ---*/
.sub-slide-item2{
  height: 28px !important;
  padding: 19px 0 19px 70px !important;
  font-size: 12px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
}
.sub-slide-menu2 .sub-slide-item2{
  height: 28px !important;
  padding: 19px 0 19px 85px !important;
  font-size: 11px;
  display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    list-style: none;
}
.sub-slide-menu2{
  display: none;
}
.sub-slide2.is-expanded .sub-slide-menu2{
  display: block;
}
.sub-angle2{
 -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease; 
    position: absolute;
    right: 20px;
    top: 13px;
}
.sub-slide2.is-expanded .sub-angle2{
  -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sub-slide.is-expanded .sub-angle{
  -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    font-size: 12px;
}
.side-menu__item .side-badge {
  padding: .25em .4em!important;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  line-height: 1;
  color: #fff;
}
.side-menu__icon {
	color: #555b6d;
	fill: #555b6d;
  }
  
  .sidemneu-icon2 {
	fill: #8e98db;
  }

  // --! Sidemenu LTR styles --//  


  // -- Sidemenu RTL styles --//

.rtl {
  @media (min-width: 768px) {
    &.app.sidenav-toggled {
      .app-sidebar {
        right: 250px;
      }
    }
  }
  @media (max-width: 767px) {
     &.app {
      .app-sidebar {
      right: -265px;
      margin-top: 76px !important;
      }
      &.sidenav-toggled {
        .app-sidebar {
          right: 0;
        }
      }
    }
  }  
  .app-content {
    -webkit-transition: margin-right 0.3s ease;
    -o-transition: margin-right 0.3s ease;
    transition: margin-right 0.3s ease;
  }
  @media (min-width: 768px) {
    .app-content {
      margin-right: 250px;
      margin-left: inherit;
    }
  }
  @media (min-width: 768px) {
    .app-header {
      padding-right: 250px;
    }
    &.sidebar-mini.sidenav-toggled .app-header {
      padding-right: 70px;
    }
  }
  .app-sidebar__toggle {
    .header-icon {
      margin-top: 5px;
    }
    border-left: 1px solid #e6ebf1;
    margin-right: -12px;
  }
  .app-sidebar {
    right: 0;
    border-left: 1px solid #e6ebf1;
     -webkit-transition: right 0.3s ease, width 0.3s ease;
    transition: right 0.3s ease, width 0.3s ease;
  }
  .side-menu__item {
    padding: 3px 5px 3px 22px;
  }  
  .side-menu .side-menu__icon {
   margin-left: 0.5rem;
  }
  .side-menu__label {
    text-align: right;
  }
  .slide.is-expanded {
    .angle {
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  .slide-item {
    padding: 7px 44px 7px 14px;
  }
  .angle {
    font-size:12px;
  }
  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled {
      .app-content {
        margin-right: 70px;
      }
      .app-sidebar {
        right: 0;
        -webkit-transition: right 0.3s ease, width 0.3s ease;
        transition: right 0.3s ease, width 0.3s ease; 
      }
    }
  }   
  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 {
    .app-sidebar {
      -webkit-transition: right 0.3s ease, width 0.3s ease;
      transition: right 0.3s ease, width 0.3s ease;
    }
  }
  &.sidebar-min.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    .side-menu__item {
      padding:3px 5px 3px 22px;
    }
  }
  &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    .side-menu__icon {
      margin-left: 0.5rem;
    }
  }
  .angle {
    font-size:0.95rem;
   }
   .dropdown-menu {
    &.dropdown-menu-end {
      left: 0 !important;
    }
  }
  @media (min-width: 768px) {
    footer.footer {
      padding: 1.25rem 250px 1.25rem 1.25rem;
    }
    &.sidenav-toggled footer.footer {
      padding: 1.25rem 70px 1.25rem 1.25rem;
    }
  }
  @media (min-width: 768px) {
    &.sidenav-toggled {
      .app-sidebar__logo {
        padding: 11px 12px;
        width: 70px;
        border-left: 1px solid #e6ebf1 !important;
      }
      &.sidenav-toggled1 {
        .app-sidebar__logo {
          border-left: 1px solid #e6ebf1 !important;
          width: 250px;
        }
      }
    }
  }
  .app-sidebar__logo {
    border-left: 1px solid #e6ebf1 !important;
  }
  .sub-slide-item {
    padding: 19px 60px 19px 0px !important;
  }
  .sub-side-menu__item {
    padding-right: 44px !important;
    padding-left: 20px !important;
    text-align: right;
   }
   .sub-angle {
    float: left;
  }
  .app-sidebar .side-item.side-item-category {
    text-align: right;
  }
  .sub-slide-item2{
    padding: 19px 70px 19px 0px !important;
  }
  .sub-slide-menu2 .sub-slide-item2{
    padding: 19px 85px 19px 0px !important;
  }
  .sub-angle2{
       left: 20px;
   }
   .sub-slide2.is-expanded .sub-angle2{
     -webkit-transform: rotate(-90deg);
       -ms-transform: rotate(-90deg);
       transform: rotate(-90deg);
   }
   .sub-slide.is-expanded .sub-angle{
     -webkit-transform: rotate(-90deg);
       -ms-transform: rotate(-90deg);
       transform: rotate(-90deg);
   }
   @media (min-width: 768px) {
    .app-header {
      padding-left: 0px;
      padding-bottom: 0px;
    }
  }
  @media  (min-width: 768px) {
    .search-element {
      margin-right: 16px;
      margin-left: inherit;
    }
  }
  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled .app-header {
       padding-left: 0px; 
    }
  }
  &.sidenav-toggled.sidenav-toggled1 {
    .header-brand-img {
      height: 2.6rem;
    }
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .desktop-lgo {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }  
  @media (min-width: 576px) {
    .header .main-header-end .nav-link.icon {
      padding: 2.2rem .75rem !important;
    }
  }
  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled .app-content {
      margin-left: 0px; 
    }
  }
  @media (max-width: 767.98px) {
    .app-content {
      margin-top: 50px;
      min-width: 100%;
    }
    .header .main-header-end .nav-link.icon {
      padding: 1.8rem 0.3rem !important;
    }
  }
  @media (min-width: 576px) {
    .header .nav-link.icon.layout-setting {
        border-left: 1px solid #e6ebf1 !important;
    }
  }  
  @media (min-width: 767.98px) and (max-width: 992px) {
    .header .main-header-end .nav-link.icon {
      padding: 2.2rem .3rem !important;
    }
  }
  @media (min-width: 768px) {
    &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item {
        padding: 1px 5px 1px 22px;
      }
      &.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .slide-item {
        padding: 10px 55px 10px 14px;
      }
  }
}
  @media (min-width: 768px) {
    &.rtl.sidebar-mini.sidenav-toggled .app-sidebar {
        right: -70px;
    }
    &.rtl.sidebar-mini.sidenav-toggled .app-content {
      margin-right: 0px;
    }
    &.rtl.sidebar-mini.sidenav-toggled .app-header {
      padding-right: 0px;
    }
  }
}



 // --! Sidemenu RTL styles --//



