body.rtl {
    text-align: right;
}

.rtl {

    dd {
        margin-left: 0;
        margin-right: inherit;
    }

    caption {
        text-align: left;
    }

    .list-unstyled {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-inline {
        padding-left: 0;
        padding-right: inherit;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    /*------ Scroll -------*/

    * html .fileinput-button {
        margin: 1px -3px 0 0;
    }

    *+html .fileinput-button {
        margin: 1px 0 0;
    }

    .new-list.option-dots {
        right: 10px;
        left: inherit;
    }

    .new-list2.option-dots {
        margin-right: 8px;
        margin-left: inherit;
    }

    .bg-style .header-brand-img {
        left: 55px;
        right: inherit;
    }


    .page-style1 .input-group-addon {
        border-right: 0;
        border-left: inherit;
    }

    .page-style1 .input-group-append {
        border-left: 0;
        border-right: inherit;
        border-radius: 0 5px 5px 0;
        border-radius: 5px 0px 5px 0px;
    }

    .page-style1 .btn-svgs .svg-icon {
        left: 37%;
        right: inherit;
    }

    .card-pay .tabs-menu li:last-child a {
        border-right: 0;
        border-left: inherit;
    }


    .html-code {
        border-radius: 5px 5px 0 0;
        border-radius: 0px 0px 5px 5px;
    }

    .html-code .svg-icon {
        left: 14px;
        right: inherit;
    }

    .list-style {
        padding-right: inherit;
    }

    .list-style ul {
        padding-right: 30px;
        padding-left: inherit;
    }


    .list-style2 {
        padding-right: inherit;
    }

    .list-style2 ul {
        padding-right: 30px;
        padding-left: inherit;
    }


    .list-style3 {
        padding-right: inherit;
    }

    .list-style3 ul {
        padding-right: 30px;
        padding-left: inherit;
    }


    .list-style4 {
        padding-right: inherit;
    }

    .list-style4 ul {
        padding-right: 30px;
        padding-left: inherit;
    }


    .list-style5 {
        padding-right: inherit;
    }

    .list-style5 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style6 {
        padding-right: inherit;
    }

    .list-style6 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .browse-file.form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-btn .btn-primary {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .box div:nth-child(1) {
        left: 42%;
        right: inherit;
    }

    .box div:nth-child(2) {
        right: inherit;
        left: 50%;
    }

    .box div:nth-child(3) {
        right: inherit;
        left: 6%;
    }

    .box div:nth-child(4) {
        right: inherit;
        left: 60%;
    }

    .box div:nth-child(5) {
        right: inherit;
        left: 10%;
    }

    .box div:nth-child(6) {
        right: inherit;
        left: 70%;
    }

    .box div:nth-child(7) {
        right: inherit;
        left: 80%;
    }

    .box div:nth-child(8) {
        right: inherit;
        left: 25%;
    }

    .box div:nth-child(9) {
        right: inherit;
        left: 25%;
    }

    .box div:nth-child(10) {
        right: inherit;
        left: 80%;
    }

    .box div:nth-child(11) {
        right: inherit;
        left: 50%;
    }

    .box div:nth-child(12) {
        right: inherit;
        left: 40%;
    }

    .box div:nth-child(13) {
        right: inherit;
        left: 90%;
    }

    .box div:nth-child(14) {
        right: inherit;
        left: 750%;
    }

    .box div:nth-child(15) {
        right: inherit;
        left: 68%;
    }

    .box div:nth-child(16) {
        right: inherit;
        left: 79%;
    }

    .box div:nth-child(17) {
        right: inherit;
        left: 80%;
    }

    .bootstrap-tagsinput .tag {
        margin-right: 2px;
        margin-left: inherit;
    }

    .bootstrap-tagsinput .tag [data-role="remove"] {
        margin-right: 8px;
        margin-left: inherit;
    }

    .bootstrap-tagsinput .tag {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (max-width: 767px) {
        .app-header .responsive-navbar.navbar .navbar-collapse {
            right: 0px;
            left: inherit;
        }
    }

    /*------ Accordion -------*/


    .accordion .card:first-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .accordion .card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .pagination {
        padding-left: 0;
        padding-right: inherit;
    }

    .page-link {
        margin-left: -1px;
        margin-right: inherit;
    }



    .page-item:first-child .page-link {
        margin-left: 0;
        margin-right: inherit;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .more-less {
        float: right;
    }

    .panel>.list-group .list-group-item:first-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .wrap {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    .panel-group1>.panel1:first-child .panel-heading1 {
        border-radius: 4px 4px 0 0;

    }

    .panel-title1 [aria-expanded="true"] {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .panel1:last-child .panel-body1 {
        border-radius: 0 0 4px 4px;
    }

    .panel1:last-child .panel-heading1 {
        border-radius: 0 0 4px 4px;
    }

    /* #bs-collapse icon scale option */
    .panel-title a.accordion-toggle:before {
        float: right;
    }

    .panel-title a.accordion-toggle.collapsed:before {
        float: right;
    }

    .panel-heading1 a.collapsed:before {
        right: 10px;
        left: inherit;
    }

    .panel-heading1 a:before {
        right: 10px;
        left: inherit;
    }

    #bs-collapse .panel-heading1 a:after {
        right: 5px;
        left: inherit;
    }

    /* #accordion rotate icon option */
    #accordion .panel-heading1 a:before {
        right: 5px;
        left: inherit;
    }

    .acc-header [aria-expanded="true"] {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .alert-dismissible {
        padding-right: 3.90625rem;
        padding-left: inherit;
    }

    .alert-dismissible .btn-close {
        right: 0;
        left: inherit;
    }

    .alert-icon {
        padding-left: 3rem;
        padding-right: inherit;
    }

    .alert-icon>i {
        left: 1rem;
        right: inherit;
    }

    .alert-avatar {
        padding-left: 3.75rem;
        padding-right: inherit;
    }

    .alert-avatar .avatar {
        left: .75rem;
        right: inherit;
    }

    .avatar-status {
        right: -2px;
        left: inherit;
    }

    .avatar-list {
        margin: 0 0 -.5rem;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: .5rem;
        margin-right: inherit;
    }

    .avatar-list-stacked .avatar {
        margin-left: -.8em !important;
        margin-right: inherit !important;
    }

    @-webkit-keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        100%,
        60% {
            left: 100%;
            right: -90%;
        }
    }

    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        100%,
        60% {
            left: 100%;
            right: -90%;
        }
    }

    @-webkit-keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        100%,
        60% {
            left: 107%;
            right: -8%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        100%,
        60% {
            left: 107%;
            right: -8%;
        }
    }

    /*-----Badges-----*/

    .badge {
        padding: 2px 6px 2px 6px;
    }

    .rounded-pill {
        padding-right: 1.2em;
        padding-left: 1.2em;
    }

    .badgetext {
        float: left;
    }


    .shape {
        border-width: 0 70px 40px 0;
        float: right;
    }

    .shape-text {
        right: -62px;
        top: 10px;
        left: inherit;
    }

    /*------ Breadcrumb ------*/
    .breadcrumb-item+.breadcrumb-item::before {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .breadcrumb-item1+.breadcrumb-item1::before {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .breadcrumb-arrow li:first-child a {
        border-radius: 4px 0 0 4px;
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        padding-left: 10px;
        padding-right: inherit;
    }

    .breadcrumb-arrow li:not(:first-child) {
        margin-left: -5px;
        margin-right: inherit;
    }

    .breadcrumb-arrow li a,
    .breadcrumb-arrow li:not(:first-child) span {
        padding: 0 10px 0 25px;
    }

    .breadcrumb-arrow li a:before {
        right: -11px;
        left: inherit;
        border-left-width: 11px;
    }

    .breadcrumb-arrow li a:after {
        right: -12px;
        left: inherit;
        border-left: 11px solid #6e79ca;
    }

    .breadcrumb-style4 .breadcrumb-icon {
        margin-left: 4px;
        margin-right: inherit;
    }

    /*-------- Buttons ------*/


    .btn:focus {
        box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
    }

    .btn.focus {
        box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
    }

    .btn-success {
        box-shadow: 0 0px 10px -5px rgba(56, 203, 137, 0.5);
    }

    .btn-success:focus,
    .btn-success.focus {
        box-shadow: 0 0 0 2px rgba(56, 203, 137, 0.1);
    }

    .btn-info {
        box-shadow: 0 0px 10px -5px rgba(91, 127, 255, 0.1);
    }

    .btn-info:focus,
    .btn-info.focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    .btn-info:not(:disabled):not(.disabled):active:focus,
    .btn-info:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    .show>.btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    .btn-warning {
        box-shadow: 0 0px 10px -5px rgba(255, 171, 0, 0.5);
    }

    .btn-warning:focus,
    .btn-warning.focus {
        box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
    }

    .btn-warning:not(:disabled):not(.disabled):active:focus,
    .btn-warning:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
    }

    .show>.btn-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(255, 171, 0, 0.1);
    }

    .btn-danger {
        box-shadow: 0 0px 10px -5px rgba(239, 75, 75, 0.44);
    }

    .btn-danger:focus,
    .btn-danger.focus {
        box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
    }

    .btn-danger:not(:disabled):not(.disabled):active:focus,
    .btn-danger:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
    }

    .show>.btn-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(239, 75, 75, 0.1);
    }

    .btn-light {
        box-shadow: 0 0 10px -5px rgba(244, 242, 246, 0.2);
    }

    .btn-light:focus,
    .btn-light.focus {
        box-shadow: 0 0 0 2px rgba(244, 242, 246, 0.1);
    }

    .btn-light:not(:disabled):not(.disabled):active:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(244, 242, 246, 0.1);
    }

    .show>.btn-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(244, 242, 246, 0.1);
    }

    .btn-dark:focus,
    .btn-dark.focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }

    .btn-dark:not(:disabled):not(.disabled):active:focus,
    .btn-dark:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
    }

    .show>.btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
    }

    .btn-outline-default:focus,
    .btn-outline-default.focus {
        box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
    }

    .btn-outline-default:not(:disabled):not(.disabled):active:focus,
    .btn-outline-default:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
    }

    .show>.btn-outline-default.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
    }

    .btn-outline-success {
        box-shadow: 0 0px 10px -5px rgba(56, 203, 137, 0.5);
    }

    .btn-outline-success:focus,
    .btn-outline-success.focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
    }

    .btn-outline-success:not(:disabled):not(.disabled):active:focus,
    .btn-outline-success:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
    }

    .show>.btn-outline-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
    }

    .btn-outline-info {
        box-shadow: 0 0px 10px -5px rgba(91, 127, 255, 0.5);
    }

    .btn-outline-info:focus,
    .btn-outline-info.focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }


    .btn-outline-info:not(:disabled):not(.disabled):active:focus,
    .btn-outline-info:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    .show>.btn-outline-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.1);
    }

    .btn-outline-warning {
        box-shadow: 0 0px 10px -5px rgba(255, 171, 0, 0.5);
    }

    .btn-outline-warning:focus,
    .btn-outline-warning.focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
    .btn-outline-warning:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    }

    .show>.btn-outline-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    }

    .btn-outline-danger {
        box-shadow: 0 0px 10px -5px rgba(220, 4, 65, 0.5);
    }

    .btn-outline-danger:focus,
    .btn-outline-danger.focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
    .btn-outline-danger:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
    }

    .show>.btn-outline-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
    }

    .btn-outline-light {
        box-shadow: 0 0px 10px -5px #e8ebf2;
    }

    .btn-outline-light:focus,
    .btn-outline-light.focus {
        box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    }

    .btn-outline-light:not(:disabled):not(.disabled):active:focus,
    .btn-outline-light:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    }

    .show>.btn-outline-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    }

    .btn-outline-dark {
        box-shadow: 0 0px 10px -5px rgba(244, 242, 246, 0.5);
    }

    .btn-outline-dark:focus,
    .btn-outline-dark.focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
    .btn-outline-dark:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }

    .show>.btn-outline-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    }

    /*----- Btn styles------*/
    .btn-icon {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .btn-facebook:focus,
    .btn-facebook.focus {
        box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
    }

    .btn-facebook:not(:disabled):not(.disabled):active:focus,
    .btn-facebook:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
    }

    .show>.btn-facebook.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
    }

    .btn-twitter:focus,
    .btn-twitter.focus {
        box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
    }

    .btn-twitter:not(:disabled):not(.disabled):active:focus,
    .btn-twitter:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
    }

    .show>.btn-twitter.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
    }

    .btn-google:focus,
    .btn-google.focus {
        box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
    }

    .btn-google:not(:disabled):not(.disabled):active:focus,
    .btn-google:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
    }

    .show>.btn-google.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
    }

    .btn-youtube:focus,
    .btn-youtube.focus {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
    }


    .btn-youtube:not(:disabled):not(.disabled):active:focus,
    .btn-youtube:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
    }

    .show>.btn-youtube.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
    }

    .btn-vimeo:focus,
    .btn-vimeo.focus {
        box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }


    .btn-vimeo:not(:disabled):not(.disabled):active:focus,
    .btn-vimeo:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }

    .show>.btn-vimeo.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
    }


    .btn-dribbble:focus,
    .btn-dribbble.focus {
        box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
    }


    .btn-dribbble:not(:disabled):not(.disabled):active:focus,
    .btn-dribbble:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
    }

    .show>.btn-dribbble.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
    }

    .btn-github:focus,
    .btn-github.focus {
        box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
    }


    .btn-github:not(:disabled):not(.disabled):active:focus,
    .btn-github:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
    }

    .show>.btn-github.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
    }


    .btn-instagram:focus,
    .btn-instagram.focus {
        box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
    }

    .btn-instagram:not(:disabled):not(.disabled):active:focus,
    .btn-instagram:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
    }

    .show>.btn-instagram.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
    }


    .btn-pinterest:focus,
    .btn-pinterest.focus {
        box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
    }


    .btn-pinterest:not(:disabled):not(.disabled):active:focus,
    .btn-pinterest:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
    }

    .show>.btn-pinterest.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
    }


    .btn-vk:focus,
    .btn-vk.focus {
        box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
    }


    .btn-vk:not(:disabled):not(.disabled):active:focus,
    .btn-vk:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
    }

    .show>.btn-vk.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
    }


    .btn-rss:focus,
    .btn-rss.focus {
        box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
    }

    .btn-rss:not(:disabled):not(.disabled):active:focus,
    .btn-rss:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
    }

    .show>.btn-rss.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
    }

    .btn-flickr:focus,
    .btn-flickr.focus {
        box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
    }

    .btn-flickr:not(:disabled):not(.disabled):active:focus,
    .btn-flickr:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
    }

    .show>.btn-flickr.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
    }

    .btn-bitbucket:focus,
    .btn-bitbucket.focus {
        box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
    }

    .btn-bitbucket:not(:disabled):not(.disabled):active:focus,
    .btn-bitbucket:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
    }

    .show>.btn-bitbucket.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
    }

    .btn-blue:focus,
    .btn-blue.focus {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
    }

    .btn-blue:not(:disabled):not(.disabled):active:focus,
    .btn-blue:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
    }

    .show>.btn-blue.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
    }

    .btn-indigo:focus,
    .btn-indigo.focus {
        box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
    }

    .btn-indigo:not(:disabled):not(.disabled):active:focus,
    .btn-indigo:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
    }

    .show>.btn-indigo.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
    }

    .btn-purple:focus,
    .btn-purple.focus {
        box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
    }


    .btn-purple:not(:disabled):not(.disabled):active:focus,
    .btn-purple:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
    }

    .show>.btn-purple.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
    }

    .btn-pink:focus,
    .btn-pink.focus {
        box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
    }

    .btn-pink:not(:disabled):not(.disabled):active:focus,
    .btn-pink:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
    }

    .show>.btn-pink.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
    }

    .btn-red:focus,
    .btn-red.focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
    }

    .btn-red:not(:disabled):not(.disabled):active:focus,
    .btn-red:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
    }

    .show>.btn-red.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
    }

    .btn-orange:focus,
    .btn-orange.focus {
        box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
    }

    .btn-orange:not(:disabled):not(.disabled):active:focus,
    .btn-orange:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
    }

    .show>.btn-orange.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
    }

    .btn-yellow:focus,
    .btn-yellow.focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
    }

    .btn-yellow:not(:disabled):not(.disabled):active:focus,
    .btn-yellow:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
    }

    .show>.btn-yellow.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
    }

    .btn-green:focus,
    .btn-green.focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
    }

    .btn-green:not(:disabled):not(.disabled):active:focus,
    .btn-green:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
    }

    .show>.btn-green.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
    }


    .btn-teal:focus,
    .btn-teal.focus {
        box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
    }

    .btn-teal:not(:disabled):not(.disabled):active:focus,
    .btn-teal:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
    }

    .show>.btn-teal.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
    }

    .btn-cyan:focus,
    .btn-cyan.focus {
        box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
    }

    .btn-cyan:not(:disabled):not(.disabled):active:focus,
    .btn-cyan:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
    }

    .show>.btn-cyan.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
    }


    .btn-white:focus,
    .btn-white.focus {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }

    .btn-white:not(:disabled):not(.disabled):active:focus,
    .btn-white:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }

    .show>.btn-white.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }

    .btn-gray:focus,
    .btn-gray.focus {
        box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
    }

    .btn-gray:not(:disabled):not(.disabled):active:focus,
    .btn-gray:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
    }

    .show>.btn-gray.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
    }

    .btn-gray-dark:focus,
    .btn-gray-dark.focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
    }

    .btn-gray-dark:not(:disabled):not(.disabled):active:focus,
    .btn-gray-dark:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
    }

    .show>.btn-gray-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
    }

    .btn-azure:focus,
    .btn-azure.focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
    }

    .btn-azure:not(:disabled):not(.disabled):active:focus,
    .btn-azure:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
    }

    .show>.btn-azure.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(91, 127, 255, 0.5);
    }


    .btn-lime:focus,
    .btn-lime.focus {
        box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
    }


    .btn-lime:not(:disabled):not(.disabled):active:focus,
    .btn-lime:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
    }

    .show>.btn-lime.dropdown-toggle:focus {
        box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
    }

    .btn-list>.btn:not(:last-child),
    .btn-list>.dropdown:not(:last-child) {
        margin-left: .5rem;
        margin-right: inherit;
    }

    .btn-loading:after {
        left: calc(50% - (1.4em / 2));
        right: inherit;
    }

    .btn-loading.btn-sm:after {
        left: calc(50% - (1em / 2));
        right: inherit;
    }

    .btn-group-sm>.btn-loading.btn:after {
        left: calc(50% - (1em / 2));
        right: inherit;
    }

    .btn-svgs .svg-icon {
        margin-left: 6px;
        margin-right: inherit;
    }

    .data-table-btn {
        left: 80px;
        right: inherit;
    }


    /*------ Carousel -------*/

    .carousel-control-prev {
        right: 0;
        left: inherit;
    }

    .carousel-control-next {
        left: 0;
        right: inherit;
    }

    .carousel-indicators {
        right: 0;
        left: 0;
        padding-left: 0;
        padding-right: inherit;
        margin-right: 15%;
        margin-left: 15%;
    }

    .carousel-indicators button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators button::after {
        left: 0;
        right: inherit;
    }

    .carousel-indicators1 {
        right: 0;
        left: 0;
        padding-left: 0;
        padding-right: inherit;
        margin-right: 15%;
        margin-left: 15%;
    }

    .carousel-indicators1 button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators1 button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators1 button::after {
        left: 0;
        right: inherit;
    }

    .carousel-indicators2 {
        right: 10px;
        left: inherit;
    }

    .carousel-indicators2 button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators2 button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators2 button::after {
        left: 0;
        right: inherit;
    }


    .carousel-indicators3 {
        left: 10px;
        right: inherit;
        padding-left: 0;
        padding-right: inherit;
    }

    .carousel-indicators3 button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators3 button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators3 button::after {
        left: 0;
        right: inherit
    }

    .carousel-indicators4 {
        right: 10px;
        left: inherit;
        padding-left: 0;
        padding-right: inherit;
    }

    .carousel-indicators4 button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators4 button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators4 button::after {
        left: 0;
        right: inherit;
    }

    .carousel-indicators5 {
        left: 10px;
        right: inherit;
        padding-left: 0;
        padding-right: inherit;
    }

    .carousel-indicators5 button {
        margin-right: 3px;
        margin-left: 3px;
    }

    .carousel-indicators5 button::before {
        left: 0;
        right: inherit;
    }

    .carousel-indicators5 button::after {
        left: 0;
        right: inherit;
        display: inline-block;
    }

    .carousel-caption {
        right: 15%;
        left: 15%;
    }

    .carousel-item-background {
        left: 0;
        right: 0;
    }

    .carousel-top-right-indicators {
        right: 15px !important;
        left: inherit !important;
    }

    .carousel-top-left-indicators {
        left: 15px !important;
        right: inherit !important;
    }

    .carousel-bottom-right-indicators {
        right: 15px !important;
        left: inherit !important;
    }

    .carousel-bottom-left-indicators {
        left: 15px !important;
        right: inherit !important;
    }


    .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-right: 0.3em solid transparent;
        border-left: 0.3em solid transparent;
    }

    .dropdown-toggle:empty::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropdown-menu {
        left: 0;
        right: inherit;
        float: right;
        text-align: right;
        box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
    }

    .dropup .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: inherit;
        border-right: 0.3em solid transparent;
        border-left: 0.3em solid transparent;
    }

    .dropup .dropdown-toggle:empty::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropend .dropdown-menu {
        right: auto;
        left: 100%;
        margin-left: 0.125rem;
        margin-right: inherit;
    }

    .dropend .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: inherit;
        border-left: 0;
        border-right: 0.3em solid;
    }

    .dropend .dropdown-toggle:empty::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropstart .dropdown-menu {
        right: 100%;
        left: auto;
        margin-right: 0.125rem;
        margin-left: inherit;
    }

    .dropstart .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: inherit;
    }

    .dropstart .dropdown-toggle::before {
        margin-left: 0.255em;
        margin-right: inherit;
        border-left: 0.3em solid;
        border-right: inherit;
    }

    .dropstart .dropdown-toggle:empty::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .header .dropdown-menu[data-popper-placement^="top"],
    .header .dropdown-menu[data-popper-placement^="end"],
    .header .dropdown-menu[data-popper-placement^="bottom"],
    .header .dropdown-menu[data-popper-placement^="start"] {
        box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
        border-top-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
    }

    .header-notify .show.dropdown-menu {
        right: -198px !important;
    }

    .header-message .show.dropdown-menu {
        right: -252px !important;
    }

    .profile-dropdown .show.dropdown-menu[data-popper-placement^="bottom"] {
        right: -136px !important;
    }

    .dropdown-menu[data-popper-placement^="top"],
    .dropdown-menu[data-popper-placement^="end"],
    .dropdown-menu[data-popper-placement^="bottom"],
    .dropdown-menu[data-popper-placement^="start"] {
        right: auto;
        left: inherit;
    }

    .notifyimg {
        float: left;
    }

    .dropdown-item {
        text-align: right;
    }

    .dropdown-menu-arrow.dropdown-menu-end:before {
        left: 15px;
        right: inherit;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }

    .dropdown-menu-arrow.dropdown-menu-end:after {
        left: 16px;
        right: inherit;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }

    .dropdown-menu-arrow.dropdown-menu-start:before {
        left: 15px;
        right: inherit;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }

    .dropdown-menu-arrow.dropdown-menu-start:after {
        left: 16px;
        right: inherit;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
    }

    .btn-group .btn+.btn,
    .btn-group .btn+.btn-group {
        margin-right: -1px;
    }

    .btn-group .btn-group+.btn,
    .btn-group .btn-group+.btn-group {
        margin-right: -1px;
        margin-left: inherit;
    }

    .btn-group-vertical .btn+.btn,
    .btn-group-vertical .btn+.btn-group {
        margin-left: -1px;
        margin-right: inherit;
    }

    .btn-group-vertical .btn-group+.btn,
    .btn-group-vertical .btn-group+.btn-group {
        margin-left: -1px;
        margin-right: inherit;
    }

    .btn-toolbar {
        -ms-flex-pack: left;
        justify-content: flex-left;
    }

    .btn-group>.btn:first-child {
        margin-left: 0;
        margin-right: inherit;
    }

    .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group>.btn-group:not(:last-child)>.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .dropdown-toggle-split {
        padding-right: 0.5625rem;
        padding-left: 0.5625rem;
    }

    .dropdown-toggle-split::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropup .dropdown-toggle-split::after,
    .dropend .dropdown-toggle-split::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropstart .dropdown-toggle-split::before {
        margin-left: 0;
        margin-right: inherit;
    }

    .btn-sm+.dropdown-toggle-split,
    .btn-group-sm>.btn+.dropdown-toggle-split {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
    }

    .btn-lg+.dropdown-toggle-split,
    .btn-group-lg>.btn+.dropdown-toggle-split {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }

    .btn-group-vertical {
        -ms-flex-align: left;
        align-items: flex-left;
    }

    .btn-group-vertical>.btn+.btn,
    .btn-group-vertical>.btn+.btn-group {
        margin-left: 0;
        margin-right: inherit;
    }

    .btn-group-vertical>.btn-group+.btn,
    .btn-group-vertical>.btn-group+.btn-group {
        margin-left: 0;
        margin-right: inherit;
    }

    .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical>.btn-group:not(:last-child)>.btn {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-group-vertical>.btn:not(:first-child),
    .btn-group-vertical>.btn-group:not(:first-child)>.btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    /*-----List Of Dropdwons-----*/
    btn.dropdown-toggle~.dropdown-menu {
        box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    }

    ul.dropdown-menu li.dropdown ul.dropdown-menu {
        box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    }

    .media {
        align-items: flex-left;
    }

    .dropdown-media-list .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    #sidebar .dropdown-menu {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    .dropdown-menu-arrow.dropdown-menu-end:before,
    .dropdown-menu-arrow.dropdown-menu-end:after {
        right: auto;
        left: 22px;
    }

    .dropdown-toggle:empty:after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropdown-icon {
        margin-right: .5rem;
        margin-left: -.5rem;
    }

    .dropdown-toggle.split-dropdown {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {

        .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search {
            left: 0;
            right: 0;
        }

        .responsive-navbar .collapse.show .dropdown .show.dropdown-menu {
            left: 0px !important;
            right: 0px !important;
        }
    }

    /*-----Lists-----*/
    .list-group {
        padding-right: 0;
        padding-left: inherit;
    }

    .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .list-group-item:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .list-group-flush .list-group-item {
        border-right: 0;
        border-left: 0;
    }

    .btn-close {
        float: left;
    }

    .list-inline-dots .list-inline-item+.list-inline-item:before {
        margin-left: -2px;
        margin-right: 3px;
    }

    .icon-absolute {
        right: 10px;
        left: inherit;
    }


    /*------ Modal -------*/

    .modal-open .modal {
        padding-right: 0px !important;
        padding-left: inherit !important;
    }

    .modal {
        right: 0;
        left: 0;
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .modal-open,
    .modal-backdrop.fade.show {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .modal-backdrop {
        right: 0;
        left: 0;
    }

    .modal-header {
        -ms-flex-align: left;
        align-items: flex-left;
    }

    .modal-header .btn-close {
        margin: -1rem auto -1rem -1rem;
    }

    .modal-footer {
        -ms-flex-pack: right;
        justify-content: flex-right;
    }

    .modal-footer> :not(:first-child) {
        margin-left: .25rem;
        margin-right: inherit;
    }

    .modal-footer> :not(:last-child) {
        margin-left: .25rem;
        margin-right: inherit;
    }

    .modal.effect-rotate-bottom.show .modal-dialog {
        transform: translateY(0%) rotateX(0deg);
    }

    .modal.effect-just-me .modal-header {
        padding-left: 0;
        padding-right: 0;
    }

    .modal.effect-just-me .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .modal.effect-just-me .modal-footer {
        padding-left: 0;
        padding-right: 0;
    }

    /*------ Navigation -------*/
    .nav {
        padding-left: 0;
        padding-right: inherit;
    }

    .nav-tabs .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .navbar-brand {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .navbar-nav {
        padding-left: 0;
        padding-right: inherit;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

    @media (max-width: 575.98px) {

        .navbar-expand-sm>.container,
        .navbar-expand-sm>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 576px) {
        .navbar-expand-sm {
            -ms-flex-pack: left;
            justify-content: flex-left;
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    @media (max-width: 767.98px) {

        .navbar-expand-md>.container,
        .navbar-expand-md>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 768px) {
        .navbar-expand-md {
            -ms-flex-pack: left;
            justify-content: flex-left;
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    @media (max-width: 991.98px) {

        .navbar-expand-lg>.container,
        .navbar-expand-lg>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 992px) {
        .navbar-expand-lg {
            -ms-flex-pack: left;
            justify-content: flex-left;
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    @media (max-width: 1279.98px) {

        .navbar-expand-xl>.container,
        .navbar-expand-xl>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @media (min-width: 1280px) {
        .navbar-expand-xl {
            -ms-flex-pack: left;
            justify-content: flex-left;
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    .navbar-expand {
        -ms-flex-pack: left;
        justify-content: flex-left;
    }

    .navbar-expand>.container,
    .navbar-expand>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*--navtabs--*/

    .nav1 {
        padding-left: 0;
        padding-right: inherit;
    }

    .nav-tabs .nav-item1.nav-link {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .nav-tabs .nav-item1 i {
        margin-right: .25rem;
        margin-left: inherit;
    }

    .nav-tabs .nav-submenu {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border-radius: 0 0 3px 3px;
    }

    .header .nav-link .badge,
    .header .nav-item .badge {
        right: 10px;
        left: inherit;
    }

    @media (max-width: 767px) {

        .shop-dec .col-md-6.pr-0 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .shop-dec .col-md-6.pl-0 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }
    }

    @media (max-width: 768px) and (min-width: 481px) {
        .header-brand {
            margin-left: 20px;
            margin-right: inherit;
        }
    }


    .nav-tabs .nav-item i {
        margin-right: .25rem;
        margin-right: 10px;
    }

    /*--------panel----------*/

    .expanel-heading {
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
    }

    .expanel-footer {
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .media {
        -ms-flex-align: left;
        align-items: flex-left;
    }

    /*------ progress sizes ------*/

    .progress-bar-indeterminate:after {
        left: 0;
        right: inherit;
        will-change: left, right;
    }

    .progress-bar-indeterminate:before {
        left: 0;
        right: inherit;
        will-change: left, right;
    }

    /*---- Tags-----*/

    .tag-addon:last-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -.5rem 0 .5rem;
    }

    .thumbnail a>img,
    .thumbnail>img {
        margin-right: auto;
        margin-left: auto;
    }

    .video-list-thumbs h2 {
        margin: 8px 0 0;
    }

    .video-list-thumbs .fa {
        right: 39%;
        left: inherit;
    }


    .video-list-thumbs .duration {
        left: 12px;
        right: inherit;
        padding: 2px 3px 1px;
    }


    @media (max-width: 768px) {
        .video-list-thumbs .fa {
            right: 44%;
            left: inherit;
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {

        .video-list-thumbs h2 {
            margin: 8px 0 0;
        }
    }

    /*-------Tooptip &popover------*/
    .bd-example {
        border-width: .2rem 0 0;
    }


    .tooltip {
        text-align: left;
    }

    // .tooltip.bs-tether-element-attached-left,
    // .tooltip.tooltip-right {
    //     margin-left: 3px;
    //     margin-right: inherit;
    // }

    // .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
    // .tooltip.tooltip-top .tooltip-inner::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    //     border-width: 5px 5px 0;
    // }

    // .tooltip.bs-tether-element-attached-left .tooltip-inner::before,
    // .tooltip.tooltip-right .tooltip-inner::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 5px 5px 5px 0;
    //     border-right-color: #000;
    // }

    // .tooltip.bs-tether-element-attached-top .tooltip-inner::before,
    // .tooltip.tooltip-bottom .tooltip-inner::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    //     border-width: 0 5px 5px;
    // }

    // .tooltip.bs-tether-element-attached-right,
    // .tooltip.tooltip-left {
    //     margin-left: -3px;
    //     margin-right: inherit;
    // }

    // .tooltip.bs-tether-element-attached-right .tooltip-inner::before,
    // .tooltip.tooltip-left .tooltip-inner::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 5px 0 5px 5px;
    //     border-left-color: #000;
    // }

    // /*alerts*/

    // .bs-tooltip-end .tooltip-arrow,
    // .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    //     right: 0;
    //     left: inherit;
    // }

    // .bs-tooltip-end .tooltip-arrow::before,
    // .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 0.4rem 0.4rem 0.4rem 0.4rem;
    //     border-left-color: #000;
    // }


    // .bs-tooltip-bottom .tooltip-arrow::before,
    // .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    //     border-width: 0 0.4rem 0.4rem;
    // }


    // .bs-tooltip-start .tooltip-arrow,
    // .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    //     left: 0;
    //     right: inherit;
    // }

    // .bs-tooltip-start .tooltip-arrow::before,
    // .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 0.4rem 0.4rem 0.4rem 0.4rem;
    //     border-right-color: #000;
    // }

    // .bs-popover-top .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before,
    // .bs-popover-top .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    //     border-width: 0.5rem 0.25rem 0;
    // }

    // .bs-popover-end,
    // .bs-popover-auto[data-popper-placement^="right"] {
    //     margin-left: 0.5rem;
    //     margin-right: inherit;
    // }

    // .bs-popover-end .popover-arrow,
    // .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: inherit;
    // }

    // .bs-popover-end .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before,
    // .bs-popover-end .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    //     border-width: 0.25rem 0.5rem 0.25rem 0;
    // }

    // .bs-popover-end .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-right-color: #dee3eb;
    // }

    // .bs-popover-end .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    //     left: 1px;
    //     right: inherit;
    //     border-right-color: #fff;
    // }

    // .bs-popover-bottom .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before,
    // .bs-popover-bottom .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    //     border-width: 0 0.25rem 0.5rem 0.25rem;
    // }

    // .bs-popover-bottom .popover-header::before,
    // .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -0.25rem;
    //     margin-right: inherit;
    // }

    // .bs-popover-start,
    // .bs-popover-auto[data-popper-placement^="left"] {
    //     margin-right: 0.5rem;
    //     margin-left: inherit;
    // }

    // .bs-popover-start .popover-arrow,
    // .bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: calc((0.5rem + 1px) * -1);
    //     left: inherit;
    // }

    // .bs-popover-start .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before,
    // .bs-popover-start .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    //     border-width: 0.25rem 0 0.25rem 0.5rem;
    // }

    // .bs-popover-start .popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-left-color: #dee3eb;
    // }

    // .bs-popover-start .popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    //     right: 1px;
    //     left: inherit;
    //     border-left-color: #fff;
    // }

    .dropdown-item .bd-example {
        border-width: .2rem 0 0;
    }


    // .dropdown-item .tooltip {
    //     text-align: left;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-left,
    // .dropdown-item .tooltip.tooltip-right {
    //     margin-left: 3px;
    //     margin-right: inherit;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
    // .dropdown-item .tooltip.tooltip-top .tooltip-inner::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    //     border-width: 5px 5px 0;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-left .tooltip-inner::before,
    // .dropdown-item .tooltip.tooltip-right .tooltip-inner::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 5px 5px 5px 0;
    //     border-right-color: #000;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-top .tooltip-inner::before,
    // .dropdown-item .tooltip.tooltip-bottom .tooltip-inner::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    //     border-width: 0 5px 5px;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-right,
    // .dropdown-item .tooltip.tooltip-left {
    //     margin-left: -3px;
    //     margin-right: inherit;
    // }

    // .dropdown-item .tooltip.bs-tether-element-attached-right .tooltip-inner::before,
    // .dropdown-item .tooltip.tooltip-left .tooltip-inner::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 5px 0 5px 5px;
    //     border-left-color: #000;
    // }

    // .dropdown-item .bs-tooltip-top .tooltip-arrow::before,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    //     border-width: 0.4rem 0.4rem 0;
    // }

    // .dropdown-item .bs-tooltip-end .tooltip-arrow,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    //     left: 0;
    //     right: inherit;
    // }

    // .dropdown-item .bs-tooltip-end .tooltip-arrow::before,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 0.4rem 0.4rem 0.4rem 0;
    //     border-right-color: #000;
    // }

    // .dropdown-item .bs-tooltip-bottom .tooltip-arrow::before,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    //     border-width: 0 0.4rem 0.4rem;
    // }

    // .dropdown-item .bs-tooltip-start .tooltip-arrow,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    //     right: 0;
    //     left: inherit;
    // }

    // .dropdown-item .bs-tooltip-start .tooltip-arrow::before,
    // .dropdown-item .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 0.4rem 0 0.4rem 0.4rem;
    //     border-left-color: #000;
    // }

    // .dropdown-item .bs-popover-top .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before,
    // .dropdown-item .bs-popover-top .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    //     border-width: 0.5rem 0.25rem 0;
    // }

    // .dropdown-item .bs-popover-end,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] {
    //     margin-left: 0.5rem;
    //     margin-right: inherit;
    // }

    // .dropdown-item .bs-popover-end .popover-arrow,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: inherit;
    // }

    // .dropdown-item .bs-popover-end .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before,
    // .dropdown-item .bs-popover-end .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    //     border-width: 0.25rem 0.5rem 0.25rem 0;
    // }

    // .dropdown-item .bs-popover-end .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-right-color: #dee3eb;
    // }

    // .dropdown-item .bs-popover-end .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
    //     left: 1px;
    //     right: inherit;
    //     border-right-color: #fff;
    // }

    // .dropdown-item .bs-popover-bottom .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before,
    // .dropdown-item .bs-popover-bottom .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    //     border-width: 0 0.25rem 0.5rem 0.25rem;
    // }

    // .dropdown-item .bs-popover-bottom .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
    //     border-bottom-color: #dee3eb;
    // }

    // .dropdown-item .bs-popover-bottom .popover-header::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -0.25rem;
    //     margin-right: inherit;
    // }

    // .dropdown-item .bs-popover-start,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] {
    //     margin-right: 0.5rem;
    //     margin-left: inherit;
    // }

    // .dropdown-item .bs-popover-start .popover-arrow,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: calc((0.5rem + 1px) * -1);
    //     left: inherit;
    // }

    // .dropdown-item .bs-popover-start .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before,
    // .dropdown-item .bs-popover-start .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    //     border-width: 0.25rem 0 0.25rem 0.5rem;
    // }

    // .dropdown-item .bs-popover-start .popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-left-color: #dee3eb;
    // }

    // .dropdown-item .bs-popover-start .popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
    //     right: 1px;
    //     left: inherit;
    //     border-left-color: #fff;
    // }

    // .dropdown-item .tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
    // .dropdown-item .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
    // .dropdown-item .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
    // .dropdown-item .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -2px;
    //     margin-right: inherit;
    // }

    // .dropdown-item .popover-static-demo .bs-popover-top .popover-arrow,
    // .dropdown-item .popover-static-demo .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
    // .dropdown-item .popover-static-demo .bs-popover-bottom .popover-arrow,
    // .dropdown-item .popover-static-demo .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    // }

    // .dropdown-item .popover {
    //     -webkit-filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    //     filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    //     left: 0;
    //     right: inherit;
    //     text-align: left;
    //     text-align: left;
    // }

    // .dropdown-item .popover .popover-arrow {
    //     margin-left: calc(.25rem + -5px);
    //     margin-right: inherit;
    // }

    // .dropdown-item .bs-popover-top>.popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
    //     border-width: 0.5rem 0.5rem 0;
    // }

    // .dropdown-item .bs-popover-top>.popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
    //     border-width: 0.5rem 0.5rem 0;
    // }

    // .dropdown-item .bs-popover-end>.popover-arrow,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: inherit;
    // }

    // .dropdown-item .bs-popover-end>.popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 0.5rem 0.5rem 0.5rem 0;
    //     border-right-color: rgba(0, 0, 0, 0.25);
    // }

    // .dropdown-item .bs-popover-end>.popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
    //     left: 1px;
    //     right: inherit;
    //     border-width: 0.5rem 0.5rem 0.5rem 0;
    //     border-right-color: #fff;
    // }

    // .dropdown-item .bs-popover-bottom>.popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
    //     border-width: 0 0.5rem 0.5rem 0.5rem;
    // }

    // .dropdown-item .bs-popover-start>.popover-arrow,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
    //     right: calc((0.5rem + 1px) * -1);
    //     left: inherit;
    // }

    // .dropdown-item .bs-popover-start>.popover-arrow::before,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 0.5rem 0 0.5rem 0.5rem;
    //     border-left-color: #d3dfea;
    // }

    // .dropdown-item .bs-popover-start>.popover-arrow::after,
    // .dropdown-item .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
    //     right: 1px;
    //     left: inherit;
    //     border-width: 0.5rem 0 0.5rem 0.5rem;
    //     border-left-color: #fff;
    // }

    // .dropdown-item .popover-header {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border-top-left-radius: calc(0.3rem - 1px);
    //     border-top-right-radius: calc(0.3rem - 1px);
    //     border-top-left-radius: calc(3px - 1px);
    //     border-top-right-radius: calc(3px - 1px);
    // }

    // .dropdown-item .popover-head-primary.bs-popover-start .popover-arrow,
    // .dropdown-item .popover-head-primary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: -7px;
    //     left: inherit;
    // }

    // .dropdown-item .popover-head-primary.bs-popover-end .popover-arrow,
    // .dropdown-item .popover-head-primary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: -7px;
    //     right: inherit;
    // }


    // .dropdown-item .popover-head-secondary.bs-popover-start .popover-arrow,
    // .dropdown-item .popover-head-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: -7px;
    //     left: inherit;
    // }

    // .dropdown-item .popover-head-secondary.bs-popover-end .popover-arrow,
    // .dropdown-item .popover-head-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: -7px;
    //     right: inherit;
    // }

    // .dropdown-item .popover-head-primary .popover-body,
    // .dropdown-item .popover-head-secondary .popover-body {
    //     border-bottom-right-radius: 2px;
    //     border-bottom-left-radius: 2px;
    // }

    // .dropdown-item .popover-primary .popover-header,
    // .dropdown-item .popover-secondary .popover-header {
    //     padding: 0 0 15px;
    // }

    // .tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
    // .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
    // .tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
    // .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -2px;
    //     margin-right: inherit;
    // }


    // .popover-static-demo .bs-popover-top .popover-arrow,
    // .popover-static-demo .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
    // .popover-static-demo .bs-popover-bottom .popover-arrow,
    // .popover-static-demo .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
    //     left: 50%;
    //     right: inherit;
    //     margin-left: -5px;
    //     margin-right: inherit;
    // }

    // .popover {
    //     left: 0;
    //     right: inherit;
    //     text-align: left;
    //     text-align: left;
    // }

    // .popover .popover-arrow {
    //     margin-left: calc(.25rem + -5px);
    //     margin-right: inherit;
    // }


    // .bs-popover-top>.popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
    //     border-width: 0.5rem 0.5rem 0;
    // }

    // .bs-popover-top>.popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
    //     border-width: 0.5rem 0.5rem 0;
    // }

    // .bs-popover-end>.popover-arrow,
    // .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: auto;
    // }

    // .bs-popover-end>.popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
    //     right: -7px;
    //     left: auto;
    //     border-width: 0.5rem 0.5rem 0.5rem 0.5rem;
    //     border-left-color: rgba(0, 0, 0, 0.25);
    // }

    // .bs-popover-end>.popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
    //     right: -7px;
    //     left: auto;
    //     border-width: 0.5rem 0.5rem 0.5rem 0.5rem;
    //     border-left-color: #fff;
    // }

    // .bs-popover-bottom>.popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
    //     border-width: 0 0.5rem 0.5rem 0.5rem;
    // }

    // .bs-popover-bottom>.popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
    //     border-width: 0 0.5rem 0.5rem 0.5rem;
    // }

    // .bs-popover-start>.popover-arrow,
    // .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
    //     left: calc((0.5rem + 1px) * -1);
    //     right: auto;
    // }

    // .bs-popover-start>.popover-arrow::before,
    // .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
    //     left: -7px;
    //     right: auto;
    //     border-width: 0.5rem 0.5rem 0.5rem 0.5rem;
    //     border-right-color: #d3dfea;
    // }

    // .bs-popover-start>.popover-arrow::after,
    // .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
    //     left: -7px;
    //     right: auto;
    //     border-width: 0.5rem 0.5rem 0.5rem 0.5rem;
    //     border-right-color: #fff;
    // }

    // .popover-header {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border-top-left-radius: calc(0.3rem - 1px);
    //     border-top-right-radius: calc(0.3rem - 1px);
    //     border-top-left-radius: calc(3px - 1px);
    //     border-top-right-radius: calc(3px - 1px);
    // }


    // .popover-head-primary.bs-popover-start .popover-arrow,
    // .popover-head-primary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: -7px;
    //     left: inherit;
    // }

    // .popover-head-primary.bs-popover-end .popover-arrow,
    // .popover-head-primary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: -7px;
    //     right: inherit;
    // }

    // .popover-head-secondary.bs-popover-start .popover-arrow,
    // .popover-head-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
    //     right: -7px;
    //     left: inherit;
    // }

    // .popover-head-secondary.bs-popover-end .popover-arrow,
    // .popover-head-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
    //     left: -7px;
    //     right: inherit;
    // }

    // .popover-head-primary .popover-body,
    // .popover-head-secondary .popover-body {
    //     border-bottom-right-radius: 2px;
    //     border-bottom-left-radius: 2px;
    // }

    // .popover-primary .popover-header {
    //     padding: 0 0 15px;
    // }

    // .popover-secondary .popover-header {
    //     padding: 0 0 15px;
    // }

    /*----- Typography ------*/


    /*------ Media object ------*/

    .media-right,
    .media>.pull-right {
        padding-left: 10px;
        padding-right: inherit;
    }

    .media-left,
    .media>.pull-left {
        padding-right: 10px;
        padding-left: inherit;
    }


    /* ###### components ####### */

    .fc-view.fc-listMonth-view .fc-scroller,
    .fc-view.fc-listWeek-view .fc-scroller {
        padding: 0 20px 20px;
    }

    .fc-view.fc-listMonth-view .fc-list-item,
    .fc-view.fc-listWeek-view .fc-list-item {
        border-left: 4px solid transparent;
    }

    .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
    .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
        border-right: 1px solid #e6ebf1;
    }

    .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
    .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
        border-right: 1px solid #e9edf4;
    }

    .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 20%;
        margin-right: inherit;
    }

    .fc-view.fc-listMonth-view .fc-list-item-time,
    .fc-view.fc-listWeek-view .fc-list-item-time {
        padding: 12px 15px 2px;
    }

    .fc-view.fc-listMonth-view .fc-list-item-title,
    .fc-view.fc-listWeek-view .fc-list-item-title {
        padding: 0 15px 12px;
    }

    @media (min-width: 576px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-left: 15%;
            margin-right: inherit;
        }
    }

    @media (min-width: 768px) {


        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-left: 12%;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {


        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-left: 10%;
            margin-right: inherit;
        }

    }

    @media (min-width: 1200px) {

        .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
        .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
            margin-left: 8%;
            margin-right: inherit;
        }
    }


    /*-----Chat----*/

    #messages-main .ms-user {
        border-left: 0;
    }

    #messages-main .ms-user>div {
        padding: 3px 5px 0 15px;
    }

    #messages-main #ms-compose {
        right: 30px;
        left: inherit;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    }

    @media (min-width: 992px) {
        #messages-main .ms-menu {
            right: 0;
            left: inherit;
            border-left: 1px solid #e6ebf1;
        }

        #messages-main .ms-body {
            padding-right: 500px;
            padding-left: inherit;
        }

        .msb-reply {
            border-left: 0 !important;
            border-right: 0 !important;
        }
    }

    @media (max-width: 480px) {
        .ah-actions {
            margin-left: 10px !important;
            margin-right: inherit !important;
        }
    }

    .message-feed.right>.pull-right {
        margin-left: 15px;
        margin-right: inherit;
    }

    .message-feed:not(.right) .mf-content {
        margin-left: 15px;
        margin-right: inherit;
        border-radius: 4px 12px 4px 12px;
    }

    .message-feed.right .mf-content {
        margin-right: 15px;
        margin-left: inherit;
    }

    .mf-date {
        margin-left: 15px;
        margin-right: 15px;
    }

    .msb-reply button {
        left: 9px;
        right: inherit;
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .actions>li>a {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        margin-right: 5px;
        margin-left: inherit;
    }

    #ms-menu-trigger {
        left: 0;
        right: inherit;
        padding-right: 10px;
        padding-left: inherit;
        text-align: right;
    }

    .message-feed.right {
        text-align: right;
    }

    .ah-actions {
        float: left !important;
    }

    .ms-menu-trigger {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        float: left;
    }

    /*=====================================
  Inbox Message Style
  =======================================*/

    .inbox-message ul li:hover,
    .inbox-message ul li:focus {
        background: #f0f0f2;
        z-index: 0;
    }

    .chat-conatct-list .media:hover {
        z-index: inherit;
    }

    .inbox-message .message-avatar {
        left: 30px;
        right: inherit;
    }

    .inbox-message .message-body {
        margin-left: 85px;
        margin-right: inherit;
    }

    .message-body-heading h5 {
        margin: 0 0 7px 0;
    }

    .message-body h5 span {
        margin-left: 5px;
        margin-right: inherit;
    }

    .message-body-heading span {
        float: right;
    }

    .list-icons i {
        font-size: 56px;
    }


    .ms-menu .tab-menu-heading {
        padding: 0px 13px 0px 17px;
        border-radius: 5px 0 0 0;
        border-right: 0;
        border-left: 0;
    }

    .ms-menu .tab-menu-heading .tabs-menu1 ul li {
        margin: 0 10px 0 0;
    }

    .chat-conatct-list .list-group-item .chat-time {
        left: 10px;
        right: inherit;
    }

    .chat-box-single-line {
        margin: 7px 0 30px;
    }

    .chatbox .user_info {
        margin-left: 15px;
        margin-right: inherit;
    }

    .chat .video_cam {
        margin-left: 50px;
        margin-right: inherit;
    }

    .chat .video_cam span {
        margin-right: 20px;
        margin-left: inherit;
    }

    .chat .msg_cotainer {
        margin-left: 10px;
        margin-right: inherit;
        border-radius: 0px 12px 0px 12px;
    }

    .chat .msg_cotainer_sright {
        margin-right: 10px;
        margin-left: inherit;
        border-radius: 12px 0px 12px 0px;
        margin: 0 12px 10px 0;
    }

    .chat .msg_time {
        left: 0;
        right: inherit;
    }

    .chat .msg_time_sright {
        right: 0;
        left: inherit;
    }

    .chat .msg_head {
        box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
    }

    #action_menu_btn {
        right: 36px;
        left: inherit;
    }

    .action_menu {
        right: 15px;
        left: inherit;
    }

    .chat .action-header {
        padding: 15px 13px 15px 17px;
    }

    .chat .dropdown-menu.dropdown-menu-end.show {
        transform: translate3d(-176px, 22px, 0px);
        left: 0px;
        right: inherit;
    }

    .chat .msb-reply textarea {
        margin-left: -42px;
        margin-right: inherit;
    }

    .chat .actions>li {
        padding: 0 0 0 2px;
    }

    .action-header {
        padding: 15px 13px 15px 17px;
    }

    .modal-content.chat .card {
        border-radius: 4px 4px 0 0;
    }


    .contact-href {
        left: 0;
        right: inherit;
    }

    .chat-conatct-list .option-dots {
        right: 10px;
        left: inherit;
    }

    .modal-content .actions>li>a {
        box-shadow: 0px 2px 3px rgba(16, 16, 38, 0.1);
    }

    @media (min-width: 992px) {
        .main-content-left-chat {
            border-left: 0;
        }

        .main-chat-footer {
            left: auto;
            right: auto;
        }

        .main-chat-contacts-wrapper {
            padding: 10px 0px 10px 15px;
        }

        .main-nav-line-chat {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .main-nav-line-chat .nav-link {
        margin-left: 10px;
        margin-right: inherit;
    }

    .main-nav-line-chat .nav-link.active::before {
        left: 0;
        right: 0;
    }

    .main-chat-contacts {
        padding-left: 20px;
        padding-right: inherit;
    }

    .main-chat-list .media+.media::before {
        left: 0;
        right: 0;
    }

    .main-chat-list .main-img-user span {
        left: -2px;
        right: inherit;
        box-shadow: 0 0 0 2px #fff;
    }

    .main-chat-list .media-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-header {
        justify-content: flex-left;
        padding: 14.2px 20px 14.2px;
    }

    .main-chat-header .nav {
        margin-right: auto;
        margin-left: inherit;
    }

    @media (min-width: 576px) {

        .main-chat-footer .form-control {
            margin-right: 20px;
            margin-left: inherit;
        }

        .main-chat-body .media-body {
            margin-right: 0;
            margin-left: inherit;
        }

        .main-chat-body .media.flex-row-reverse .media-body {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-chat-body .media.flex-row-reverse .media-body {
        margin-left: 0;
        margin-right: 5px;
        align-items: flex-end;
    }

    .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
        margin-left: 15px;
        margin-right: inherit;
        border-radius: 12px 4px 12px 4px;
    }

    .main-chat-body .media-body {
        margin-left: 10px;
        margin-right: inherit;
        align-items: flex-start;
    }

    .main-chat-body .media.flex-row-reverse .media-body>div span {
        margin-left: 15px;
        margin-right: 0;
    }

    .main-chat-body .media .media-body>div span {
        margin-right: 15px;
        margin-left: 0;
    }

    .main-msg-wrapper {
        border-radius: 4px 12px 4px 12px;
    }

    .main-chat-footer {
        left: 0;
        right: 0;
        border-radius: 0 0 5px 5px;
    }

    .main-chat-footer .form-control {
        padding-right: 10px;
        padding-left: 0;
    }

    .main-msg-sright {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .msg_card_body .msg_cotainer_send {
        margin-right: 10px;
        margin-left: inherit;
    }

    .chat .msg_time_send {
        right: 0;
        left: inherit;
    }

    @media (max-width: 576px) {

        .main-contact-action {
            right: 10px;
            left: 0 !important;
        }
    }

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-left: 20px;
        padding-right: inherit;
    }

    .main-contact-label {
        padding-left: 20px;
        padding-right: inherit;
    }

    .main-contact-label::after {
        left: 0;
        right: 0;
    }

    .main-contact-item+.main-contact-item::before {
        left: 0;
        right: 0;
    }

    .main-contact-body {
        margin-right: 15px;
        margin-left: inherit;
    }

    .main-contact-info-header {
        padding-right: 20px;
        padding-left: inherit;
    }


    .main-contact-info-header .main-img-user a {
        right: 2px;
        left: inherit;
        box-shadow: 0 0 0 2px #fff;
        border-radius: 100%;
    }

    .main-contact-info-header .media-body .nav-link i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-left: 10px;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .main-contact-info-header .media-body .nav-link+.nav-link {
            margin-left: 10px;
            margin-right: inherit;
        }

        .main-contact-info-header .media-body .nav-link i {
            margin-right: 7px;
            margin-left: inherit;
        }
    }

    .main-contact-action {
        left: 20px;
        right: inherit;
    }

    .main-contact-action a i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-contact-action a+a {
        margin-right: 15px;
        left: inherit;
    }

    .main-contact-info-body {
        padding-left: 0px;
        padding-right: inherit;
    }

    .main-contact-info-body .media+.media::before {
        left: 0;
        right: 0;
    }

    .main-contact-info-body .media-body {
        margin-right: 30px;
        margin-left: inherit;
    }

    .main-contact-info-body .media::before {
        left: 0;
        right: 0;
    }

    @media (min-width: 992px) {

        .main-contact-action {
            left: 20px;
            right: inherit;
        }

        .main-contact-info-header {
            padding-right: 30px;
            padding-left: inherit;
        }

        .main-contact-info-header .media-body {
            margin-right: 30px;
            margin-left: inherit;
        }

        .main-contact-label {
            padding-right: 10px;
            padding-left: inherit;
        }

        .main-content-left-contacts .main-content-breadcrumb,
        .main-content-left-contacts .main-content-title {
            padding-left: 0;
            padding-right: inherit;
        }
    }

    /*----- Range slider -------*/

    .jsr_slider {
        left: 0;
        right: inherit;
    }

    .jsr_slider::before {
        left: 50%;
        right: inherit;
    }

    /* Merged labels */
    .jsr_label .jsr_label {
        padding-right: 0;
        padding-left: inherit;
    }

    .range {
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    .range output {
        padding: 3px 5px 2px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    /*------ Headers -------*/

    .page-subtitle {
        margin-left: 2rem;
        margin-right: inherit;
    }

    .page-options {
        margin-left: auto;
        margin-right: inherit;
    }

    .page-description {
        margin: .25rem 0 0;
    }

    .content-heading {
        margin: 2rem 0 1.5rem;
    }

    .aside {
        right: 0;
        left: inherit;
        border-left: 1px solid #e6ebf1;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }

    body.fixed-header .header {
        left: 0;
        right: 0;
    }

    .nav-unread {
        right: 1rem;
        left: inherit;
    }

    .nav-unread.badge {
        right: 0;
        left: inherit;
    }

    .nav-link.icon .nav-span {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .header-brand {
        margin-right: 0;
        margin-left: inherit;
    }

    .header-brand-img {
        margin-right: 0;
        margin-left: inherit;
    }

    .app-header1 .header-brand {
        margin-right: 0;
        margin-left: inherit;
    }

    .app-header1 .header-brand .header-brand-img {
        margin-right: 0;
        margin-left: inherit;
    }

    .header-brand-img {
        margin-right: 0;
        margin-left: inherit;
    }

    .center-block {
        margin-right: auto;
        margin-left: auto;
    }

    .header-btn.has-new:before {
        right: 4px;
        left: inherit;
    }

    .header-toggler-icon {
        left: 50%;
        right: inherit;
        margin: -2px 0 0 -.5rem;
        box-shadow: 0 5px currentColor, 0 -5px currentColor;
    }

    footer.footer {
        box-shadow: 16px 0px 16px rgba(24, 23, 38, 0.06);
    }

    .footer .social ul li {
        float: left;
    }

    .br-7 {
        border-radius: 7px !important;
    }

    .br-tl-7 {
        border-top-right-radius: 7px !important;
    }

    .br-bl-7 {
        border-bottom-right-radius: 7px !important;
    }

    .br-tr-7 {
        border-top-left-radius: 7px !important;
    }

    .br-br-7 {
        border-bottom-left-radius: 7px !important;
    }


    .br-tl-0 {
        border-top-right-radius: 0px !important;
    }

    .br-bl-0 {
        border-bottom-right-radius: 0px !important;
    }

    .br-tr-0 {
        border-top-left-radius: 0px !important;
    }

    .br-br-0 {
        border-bottom-left-radius: 0px !important;
    }

    /*------ Charts styles ------*/

    /*-----Countdown-----*/

    .card-blog-overlay:before {
        right: 0;
        left: inherit;
    }

    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        right: 0;
        left: inherit;
    }

    .login-transparent:before {
        right: 0;
        left: inherit;
    }

    @media (min-width: 768px) {
        .page-breadcrumb {
            margin: -.5rem 0 0;
        }
    }

    @media (max-width: 320px) {
        .fc-toolbar .fc-right {
            float: right;
        }
    }


    .pagination-pager .page-prev {
        margin-right: auto;
        margin-left: inherit;
    }

    .pagination-pager .page-next {
        margin-left: auto;
        margin-right: inherit;
    }

    .page-total-text {
        margin-right: 1rem;
        margin-left: inherit;
    }

    /*-------- Search ---------*/

    .header .form-inline .form-control {
        padding-left: 40px;
        padding-right: 55px;
    }

    .header .form-inline .btn {
        padding: 0px 15px 0px 15px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        right: 0;
        left: inherit;
    }

    @media (max-width: 767) {
        .header .form-inline .search-element {
            left: 10px;
            right: 10px;
            margin-left: 0;
            margin-right: inherit;
        }
    }

    @media (max-width: 1024px) {

        body.search-show:before,
        body.sidebar-show:before {
            left: 0;
            right: 0;
        }
    }

    @media (max-width: 991px) and (min-width: 767px) {
        .animated-arrow.hor-toggle span {
            margin-left: 8px;
            margin-right: inherit;
        }
    }

    @media (max-width: 575.98px) {
        .header .form-inline .search-element .form-control {
            float: left;
        }
    }

    .icons-list {
        margin: 0 -1px -1px 0;
    }

    .icons-list-item {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .img-gallery {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

    .img-gallery>.col,
    .img-gallery>[class*="col-"] {
        padding-left: .25rem;
        padding-right: .25rem;
        padding-bottom: .5rem;
    }

    .link-overlay-bg {
        left: 0;
        right: 0;
    }

    /* --------Added--------- css*/

    .richText .richText-toolbar ul li a {
        border-right: #e2e3e7 solid 1px;
    }

    .addui-slider .addui-slider-track .addui-slider-handle:hover {
        box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
    }

    .addui-slider .addui-slider-track .addui-slider-handle.addui-slider-handle-active {
        box-shadow: 0 0 5px rgba(159, 120, 255, 0.5);
    }

    .cal1 .clndr .clndr-table .header-days .header-day {
        border-left: 1px solid rgba(23, 183, 148, 0.1);
    }

    .cal1 .clndr .clndr-table tr .empty,
    .cal1 .clndr .clndr-table tr .adjacent-month,
    .cal1 .clndr .clndr-table tr .my-empty,
    .cal1 .clndr .clndr-table tr .my-adjacent-month {
        border-left: 1px solid #e6ebf1;
    }

    .cal1 .clndr .clndr-table tr .day {
        border-left: 1px solid #e6ebf1;
        border-top: 1px solid #e6ebf1;
    }

    .cal1 .clndr .clndr-table tr .day:last-child {
        border-right: papayawhip 1px solid #e6ebf1;
    }

    div.conv-form-wrapper div#messages div.message.from {
        border-top-right-radius: 0;
    }

    .job-box-filter label input.form-control {
        margin-left: 5px;
        margin-right: inherit;
    }

    .text-right {
        text-align: left;
    }

    .job-box-filter a.filtsec {
        margin-right: 15px;
        margin-left: inherit;
    }

    .job-box-filter a.filtsec i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .job-box-filter h4 i {
        margin-right: 10px;
        margin-left: inherit;
    }

    .image_outer_container .green_icon {
        right: 10px;
        left: inherit;
    }

    .input-search {
        right: 20px;
        left: 0;
    }

    .search-label {
        left: 50%;
        right: inherit;
        margin-left: -54px;
        margin-right: inherit;
    }

    .search-inline {
        left: 0;
        right: inherit;
    }

    .search-inline button[type="submit"] {
        right: 52px;
        right: 80px;
        left: inherit;
    }

    .search-inline .form-control {
        padding-left: 0;
        padding-right: inherit;
        left: 1%;
        right: inherit;
    }

    .search-inline .search-close {
        right: 0;
        left: inherit;
    }

    .lockscreen:before {
        left: -25px;
        right: 0;
        box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.4);
    }

    .table-stats table th img,
    .table-stats table td img {
        margin-right: 10px;
        margin-left: inherit;
    }

    .traffic-sales-amount {
        float: right;
    }

    .pulse {
        right: 23px;
        left: inherit;
        box-shadow: 0 0 0 rgba(247, 45, 102, 0.9);
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
        }

        70% {
            -webkit-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
        }

        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
        }
    }

    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.9);
            box-shadow: 0 0 0 0 rgba(247, 45, 102, 0.7);
        }

        70% {
            -moz-box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
            box-shadow: 0 0 0 10px rgba(247, 45, 102, 0);
        }

        100% {
            -moz-box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
            box-shadow: 0 0 0 0 rgba(247, 45, 102, 0);
        }
    }

    @media (max-width: 992px) {

        .app-content.page-body .side-app {
            padding: 6.2rem 0.75rem 0 0.75rem !important;
        }
    }

    @media (max-width: 767px) {

        .header .form-inline .search-element .btn {
            left: 25px;
            right: inherit;
        }

        .theme-layout {
            border-right: 0px !important;
        }

        .dark-mode .theme-layout {
            border-right: 0px !important;
        }

        .mobile-header .theme-layout {
            margin-right: 0px !important;
            margin-left: inherit !important;
            padding-right: 0px !important;
            padding-left: inherit !important;
        }

        .header-message .nav-link {
            margin-left: 0px !important;
            margin-right: inherit !important;
            padding-left: 0px !important;
            padding-right: inherit !important;
        }
    }

    @media (max-width: 992px) and (min-width: 768px) {

        .hor-header .header-brand-img.desktop-lgo,
        .header-brand-img.dark-logo {
            margin-left: 1rem;
            margin-right: inherit;
        }

        .animated-arrow.hor-toggle {
            margin-right: 20px;
            margin-left: inherit;
        }

        .animated-arrow.hor-toggle span {
            margin-left: 8px;
            margin-right: inherit;
        }
    }

    .activity {
        border-left: 3px solid #e6ebf1;
        margin-left: 16px;
        margin-right: inherit;
    }

    .activity .img-activity {
        left: -20px;
        right: inherit;
    }

    .activity .item-activity {
        margin-left: 40px;
        margin-right: inherit;
    }

    #AreaChart2 {
        left: -7px;
        right: inherit;
    }

    #CryptoChart,
    #CryptoChart1,
    #CryptoChart2,
    #CryptoChart3 {
        right: -7px;
        left: inherit;
    }

    /* News ticker */
    [class*="js-conveyor-"] ul li {
        margin-right: 5px;
        margin-left: inherit;
        box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
    }

    .latest-timeline ul.timeline:before {
        left: 10px;
        right: inherit;
        border-left: 1px double #e6ebf1;
    }

    .latest-timeline ul.timeline>li {
        padding-left: 2.5rem;
        padding-right: inherit;
    }

    .latest-timeline ul.timeline>li:before {
        left: 5px;
        right: inherit;
    }

    .time-data {
        margin-left: 20px;
        margin-right: inherit;
    }

    /*
   *  Accordion-Wizard-Form
   * ----------------------
   */

    .card-bgimg:before {
        left: 0;
        right: 0;
    }

    /*Horizontal Centerlogo */
    @media (min-width: 992px) {
        .top-header.hor-top-header .header-brand.center-logo .desktop-lgo {
            left: 0;
            right: 0px;
        }

        .top-header.hor-top-header .header-brand {
            margin-right: 0rem;
            margin-left: inherit;
        }

        .search-element {
            margin-right: 40px;
            margin-left: inherit;
        }
    }

    .shadow {
        box-shadow: 0 2px 16px rgba(179, 186, 212, 0.7) !important;
    }

    .shadow3 {
        box-shadow: 0 0px 6px rgba(179, 186, 212, 0.7) !important;
    }

    .apexcharts-tooltip.light {
        box-shadow: 0 0px 17px -5px rgba(68, 84, 195, 0.5);
    }

    .chart-dropshadow-primary {
        filter: drop-shadow(-6px 12px 4px rgba(68, 84, 195, 0.2));
    }

    .icon-dropshadow {
        filter: drop-shadow(0px 10px 4px #e5e9f2);
    }

    .icon-dropshadow-info {
        filter: drop-shadow(0px 4px 4px rgba(69, 170, 242, 0.3));
    }

    .icon-dropshadow-success {
        filter: drop-shadow(0px 4px 4px rgba(45, 206, 137, 0.3));
    }

    .icon-dropshadow-danger {
        filter: drop-shadow(0px 4px 4px rgba(255, 91, 81, 0.3));
    }

    .icon-dropshadow-warning {
        filter: drop-shadow(0px 4px 4px rgba(236, 180, 3, 0.3));
    }

    .chart-dropshadow {
        -webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
        filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
    }

    .GradientlineShadow {
        -webkit-filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
        filter: drop-shadow(0 -8px 4px rgba(179, 186, 212, 0.2));
    }

    .BarChartShadow {
        -webkit-filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
        filter: drop-shadow(0px 1px 4px rgba(179, 186, 212, 0.3));
    }

    .donutShadow {
        -webkit-filter: drop-shadow(-5px 0px 10px rgba(179, 186, 212, 0.4));
        filter: drop-shadow(-5px 0px 10px rgba(179, 186, 212, 0.4));
    }

    .inde4-table tr td:first-child:before {
        left: 0px;
        right: inherit;
    }

    .crypto-icon {
        width: 3rem;
        height: 3rem;
        border-radius: 4px;
        line-height: 3rem;
        text-align: center;
        font-size: 1.8rem;
        margin-right: 18px;
        margin-left: inherit;
    }

    .crypto-tabs {
        padding: 0 !important;
        border: 0 !important;
    }

    .crypto-tabs .tabs-menu1 ul li .active {
        border-radius: 3px 3px 0 0;
        box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px #b0b8d6;
    }

    .js-conveyor-example:before {
        left: 0;
        right: inherit;
    }

    .js-conveyor-example:after {
        right: 0;
        left: inherit;
    }

    .svg-icon1 {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }


    .expenses-card #Chart {
        left: -7px;
        right: inherit;
    }

    /*----- clipboard-----*/

    .clipboard-icon {
        right: 10px;
        left: inherit;
    }

    .highlight {
        border-radius: 0 0 3px 3px;
    }

    /*----- clipboard-----*/
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
        left: 4px;
        right: inherit;
    }

    .profile-media-img h6 {
        left: 15px;
        right: inherit;
    }

    .profile-media-img:before {
        left: 0;
        right: 0;
    }

    .timelineleft {
        margin: 0 0 30px 0;
    }

    .timelineleft:before {
        left: 31px;
        right: inherit;
    }

    .timelineleft>li {
        margin-right: 0;
        margin-left: inherit;
    }

    .timelineleft>li>.timelineleft-item {
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        margin-left: 60px;
        margin-right: 0;
    }

    .timelineleft>li>.timelineleft-item>.time {
        float: right;
    }

    .timelineleft>li>.fa,
    .timelineleft>li>.glyphicon,
    .timelineleft>li>.ion {
        left: 18px;
        right: inherit;
    }

    .custom-switch-indicator-lg:before {
        left: 1px;
        right: inherit;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .custom-switch-input:checked~.custom-switch-indicator-lg:before {
        left: calc(1.46rem + 1px);
        right: inherit;
    }

    .custom-switch-indicator-xl:before {
        left: 1px;
        right: inherit;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .custom-switch-input:checked~.custom-switch-indicator-xl:before {
        left: calc(1.96rem + 1px) !important;
        right: inherit !important;
    }

    .header-icon2 {
        left: 10px;
        right: inherit;
    }

    .page-rightheader .date-range-btn {
        padding-left: 40px;
        padding-right: inherit;
    }

    .main-proifle .tab-menu-heading {
        border-left: 0;
        border-right: 0;
    }

    .profile-share {
        border-radius: 0 0 5px 5px;
    }

    .profile-share a {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        margin-right: 5px;
        margin-left: inherit;
    }

    .main-proifle .profile-icon,
    .profile-footer .new,
    .pro-option {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        margin-left: 10px;
        margin-right: inherit;
    }

    .pro-details li {
        float: left;
    }

    .profile-edit textarea {
        border-radius: 5px 5px 0 0;
    }

    .social-profile-buttons li {
        float: left;
    }


    .page-header .breadcrumb-icon {
        margin-left: 4px;
        margin-right: inherit;
    }

    .main-nav-calender-event .nav-link.exclude:before {
        left: 0;
        right: 0;
    }

    .form-wizard-title {
        margin-right: 10px;
        margin-left: inherit;
    }

    .footer .social .social-icon {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .richText .richText-undo,
    .richText .richText-redo {
        border-right: #e6ebf1 solid 1px !important;
    }


    .page-leftheader ol {
        padding-left: 0;
        padding-right: inherit;
    }

    .page-leftheader .breadcrumb-item+.breadcrumb-item::before {
        padding-left: 0;
        padding-right: inherit;
    }

    .sub-pageheader .nav-link:first-child {
        padding-left: 0;
        padding-right: inherit;
    }

    .sub-pageheader .nav-link:last-child {
        padding-right: 0;
        padding-left: inherit;
    }

    .sub-pageheader .nav-link.border-end {
        border-right: 1px solid #cfd1e1 !important;
    }

    .dash1-card .ratio {
        right: -10px;
        left: inherit;
        border-radius: 15px 0px 0px 15px;
    }

    .sales-bar {
        left: -10px;
        right: inherit;
    }

    .list-bar {
        right: auto;
        left: 0;
    }

    .page-rightheader .breadcrumb-item+.breadcrumb-item {
        padding-left: 0;
        padding-right: inherit;
    }

    .card-over {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
    }

    .bread-select .select2-container .select2-selection--single .select2-selection__rrightered {
        padding-left: 10px !important;
        padding-right: inherit !important;
    }

    #talent-chart {
        -webkit-filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.5));
        filter: drop-shadow(0 6px 4px rgba(179, 186, 212, 0.2));
    }

    .widget-user-image {
        box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
        -moz-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
        -webkit-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
        -ms-box-shadow: 0px 3px 10px 0 rgba(4, 4, 7, 0.1);
    }

    .user-details .user-icon {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        margin-right: 10px;
        margin-left: inherit;
    }

    .profile-dropdown img {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .profile-dropdown .nav-link {
        padding-left: 5px;
        padding-right: inherit;
    }

    .hor-header .search-element {
        margin-left: 1rem;
        margin-right: inherit;
    }


    @media (min-width: 991px) {
        .top-header1 .header-brand2 .header-brand-img {
            left: 0;
            right: 0;
        }
    }

    #flotChart3 canvas {
        left: -12px !important;
        right: inherit !important;
    }

    #flotChart1 canvas {
        left: -12px !important;
        right: inherit !important;
    }

    #flotChart5 canvas {
        left: -12px !important;
        right: inherit !important;
    }

    #flotChart6 canvas {
        left: -12px !important;
        right: inherit !important;
    }

    .activity-timeline-content {
        margin-right: 60px;
        margin-left: inherit;
    }

    .recent-activity li {
        padding: 0rem 1.5rem 0rem 1.5rem;
    }

    .header .main-header-end .nav-link.icon {
        padding: 2.2rem .75rem !important;
        border-left: 1px solid #e6ebf1;
        border-right: 0px solid #e6ebf1;
    }

    .header .profile-dropdown {
        border-left: 1px solid #e6ebf1 !important;
        border-right: 0px !important;
    }

    #fullscreen-button {
        border-left: 1px solid #e6ebf1;
    }

    .demo-icon {
        border-left: 0px !important;
    }

    @media (max-width: 767.98px) {

        .header .profile-dropdown {
            padding: 0.7rem 0.5rem 0.7rem 0.5rem;
            border-left: 1px solid #e6ebf1 !important;
        }

        .search-show .mobile-main-header .search-element {
            left: 0;
            right: 0;
        }

        .search-show .mobile-main-header .search-element .mobile-view-search {
            padding-left: 55px;
            padding-right: 40px;
        }

        .search-show .mobile-main-header .search-element .btn {
            left: 7px;
            right: inherit;
        }

        .desktop-lgo {
            left: 0;
            right: 0;
        }

        .app-sidebar__toggle {
            border-left: 0px !important;
        }

        .app-header .header-brand {
            margin-right: 0;
            margin-left: inherit;
        }

        .app-sidebar__toggle {
            border-right: 1px solid #e6ebf1;
        }

        .pulse {
            right: 17px;
            left: inherit;
        }

        .dropdown-menu.show {
            left: 0px;
            right: inherit;
            border-bottom-right-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            box-shadow: -8px 12px 18px 0 rgba(15, 34, 58, 0.1);
        }

        .close-btn {
            right: 25px;
            left: inherit;
        }
    }

    .main-header-right {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    @media (min-width: 768px) {
        .search-element {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .widget-user-image img {
        box-shadow: 0 0 0 4px #eeeef3 !important;
    }

    .shopping-carttable .input-group .input-group-btn:first-child {
        border-right: 0;
        border-radius: 7px 0 0 7px;
    }

    .shopping-carttable .input-group .input-group-btn:last-child {
        border-left: 0;
        border-radius: 0 7px 7px 0;
    }

    .register-3 hr {
        border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
    }

    .register-3 .input-group-text {
        border-left: 0 !important;
    }

    .register-2:before {
        left: 0;
        right: 0;
    }

    .register-2 .input-group-prepend .input-group-text {
        border-radius: 5px 0px 0px 5px;
    }

    .register-2 hr.divider:after {
        left: 0;
        right: 0;
    }

    .register-2 .input-group-text {
        border-left: 0 !important;
    }

    .register1 .input-group-prepend .input-group-text {
        border-radius: 5px 0px 0px 5px;
    }

    .register1 .input-group-text {
        border-left: 0 !important;
    }

    .register-1 {
        border-radius: 0px 7px 7px 0px;
    }

    .register-1:before {
        left: 0;
        right: 0;
    }

    .construction:before {
        left: 0;
        right: 0;
    }

    .bg-contacthead:before {
        left: 0px;
        right: inherit;
        border-radius: 8px 0 0 0 !important;
    }


    .bg-gradient:before {
        left: 0;
        right: inherit;
    }

    .headerstyle-1 .header .nav-unread {
        right: 0.95rem;
        left: inherit;
    }

    .headerstyle-1 .header .dropdown-menu {
        left: initial !important;
        right: inherit !important;
    }

    .headerstyle-2 .header .dropdown-menu {
        left: initial !important;
        right: inherit !important;
    }

    .headerstyle-2 .header .nav-unread {
        right: 0.90rem;
        left: inherit;
    }

    .headerstyle-2 .header .bg-warning {
        right: 6px;
        left: inherit;
    }

    .headerstyle-3 .header .profile-dropdown {
        border-right: 0px solid #e6ebf1 !important;
    }

    .headerstyle-3 .header .pulse {
        right: 8px;
        left: inherit;
    }

    .headerstyle-3 .header .dropdown-menu {
        left: initial !important;
        right: inherit !important;
    }

    .headerstyle-3 .header .profile-dropdown .show.dropdown-menu[data-popper-placement^="bottom"] {
        left: 0px !important;
        right: inherit !important;
    }

    @media (min-width: 768px) {
        .theme-layout {
            border-right: 0px !important;
        }

        .dark-mode .theme-layout {
            border-right: 0px !important;
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {

        .theme-layout {
            border-left: 0px solid #e6ebf1 !important;
        }
    }


    #checkoutsteps .item .purchase {
        margin-right: 21px;
        margin-left: inherit;
    }

    #checkoutsteps .total {
        float: left !important;
    }

    .card-pay .tabs-menu.nav li {
        border-right: 1px solid #e6ebf1;
    }

    .card-pay .tabs-menu.nav li:last-child {
        border-right: 0px;
    }

    .dark-mode .card-pay .tabs-menu.nav li {
        border-right: 1px solid #32394e;
    }

    .dark-mode .card-pay .tabs-menu.nav li:last-child {
        border-right: 0px;
    }

    .Recent-transactions-img {
        margin-left: 12px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .theme-layout {
            border-right: 1px solid #e6ebf1 !important;
        }
    }

    .img-options-remove {
        left: 16px;
        right: inherit;
    }

    .user-contact-list .card-header.bg-gradient {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .register1:before {
        left: 0;
        right: 0;
    }

    .register-3:before {
        left: 0;
        right: 0;
    }

    @media (max-width: 992px) {
        .register1 .bg-white {
            border-top-left-radius: 7px !important;
            border-bottom-left-radius: 7px !important;
        }
    }

    .legend {
        margin-right: 10px;
        margin-left: inherit;
    }

    .morris-legends {
        padding-left: 10px;
        padding-right: inherit;
    }

    .btn-animation .btn-loaders:after {
        border-right-color: transparent !important;
        right: 20px !important;
    }

    .btn-animation .loading {
        margin-right: 20px;
        margin-left: inherit;
    }

    .btn-animation .btn-loaders {
        padding-right: 46px;
        padding-left: inherit;
    }

    .mr-negative-16 {
        margin-left: -18px;
        margin-right: inherit;
    }

    #tabs-style4 .tabs-menu ul li a {
        margin: 3px 3px 3px 40px;
    }

    @media (max-width: 480px) {

        #tabs-style4 .tabs-menu ul li a {
            margin: 3px 0px 3px 0px;
        }
    }

    @media (max-width: 327px) {
        .profile-share button.pull-right {
            float: none;
        }
    }

    .apexcharts-legend-marker {
        margin-left: 3px;
        margin-right: 0 !important;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left !important;
    }

    .dropdown-menu.dropdown-menu-start {
        left: 0 !important;
        right: auto !important;
    }

    .pull-right {
        float: left !important;
    }

    .sw-btn-next {
        margin-left: 10px !important;
        margin-right: inherit !important;
    }

    .datepicker-dropdown {
        right: auto;
        left: inherit;
    }

    .btn-list .fe {
        float: right !important;
    }

    .apexcharts-tooltip-marker {
        margin-left: 10px;
        margin-right: inherit;
    }

    .coupon.form-control {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }

    .comming .form-control {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .construction .form-control {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .cropme-slider {
        transform: translate3d(47.5px, 47.5px, 0px) rotate(-90deg) !important;
    }

    .country-selector {
        border-left: 1px solid #e6ebf1 !important;
        border-right: 0px !important;
    }


    .flag-dropdown .selected-flag {
        border-radius: 4px 0px 0px 4px !important;
        padding: 9px 24px 11px 16px !important;
    }

    /*------ Email services -------*/

    .mail-box .sm-side {
        border-radius: 4px 0 0 4px;
    }

    .mail-box .lg-side {
        border-radius: 0 4px 4px 0;
    }

    .mail-box .sm-side .user-head {
        border-radius: 4px 0 0;
    }

    .user-head .inbox-avatar {
        float: left;
    }

    .user-head .user-name {
        margin: 0 0 0 10px;
    }

    ul.inbox-nav li a i {
        padding-right: 10px;
        padding-left: inherit;
    }

    ul.labels-info li h4 {
        padding-left: 15px;
        padding-right: 15px;
    }

    ul.labels-info li a i {
        padding-right: 10px;
        padding-left: inherit;
    }

    .inbox-head .sr-input {
        float: left;
    }

    ul.inbox-pagination {
        float: left;
    }

    ul.inbox-pagination li {
        float: left;
    }

    .mail-option .chk-all,
    .mail-option .btn-group {
        margin-right: 5px;
        margin-left: inherit;
    }

    .inbox-pagination a.np-btn {
        margin-left: 5px;
        margin-right: inherit;
    }

    .inbox-pagination li span {
        margin-right: 5px;
        margin-left: inherit;
    }

    .attachment-mail ul li {
        float: left;
        margin-right: 10px;
        margin-left: inherit;
    }

    .attachment-mail ul li span {
        float: right;
    }

    .attachment-mail .file-name {
        float: left;
    }

    .fileinput-button {
        float: left;
        margin-right: 4px;
        margin-left: inherit;
    }

    .fileinput-button input {
        right: 0;
        top: 0;
    }

    /*------Email---------*/

    .mail-chats li.chat-persons a span.pro-pic {
        float: left;
    }

    .mail-chats li.chat-persons.user {
        padding: 5px 10px 0px 15px;
    }

    .online-status .status {
        justify-content: flex-left;
    }




    .payment {
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    /**
Dimmer
*/

    .dimmer .loader {
        left: 0;
        right: 0;
    }

    .sk-circle .sk-child {
        left: 0;
    }

    .sk-cube-grid .sk-cube {
        float: left;
    }

    .sk-folding-cube .sk-cube {
        float: left;
    }

    .sk-folding-cube .sk-cube:before {
        left: 0;
        right: inherit;
    }

    .double-bounce1 {
        left: 0;
        right: inherit;
    }

    .double-bounce2 {
        left: 0;
        right: inherit;
    }

    .cube1 {
        left: 0;
        right: inherit;
    }

    .cube2 {
        left: 0;
        right: inherit;
    }

    .lds-heart div {
        left: 19px;
        right: inherit;
    }

    .lds-heart div:before {
        left: -17px;
        right: inherit;
        border-radius: 50% 0 0 50%;
    }

    .lds-heart div:after {
        border-radius: 50% 50% 0 0;
    }

    @media (max-width: 575.98px) {

        .header-brand {
            margin-right: 0;
            margin-left: inherit;
        }

        .header-brand-img.mobile-logo {
            margin-left: 0.5rem;
            margin-right: inherit;
        }

        .header-brand-img.darkmobile-logo {
            margin-left: 0.5rem;
            margin-right: inherit;
        }
    }

    .table-calender {
        margin: 0 0 .75rem;
    }

    .table-calender-link:before {
        left: .25rem;
        right: inherit;
    }

    .table-header:after {
        margin-left: .5rem;
        margin-right: inherit;
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        left: 10px;
        right: inherit;
    }

    .jvectormap-spinner {
        left: 0;
        right: 0;
    }

    .jvectormap-legright-cnt-h {
        right: 0;
        left: inherit;
    }

    .jvectormap-legright-cnt-v {
        right: 0;
        left: inherit;
    }

    .jvectormap-legright-cnt-h .jvectormap-legright {
        float: left;
        margin: 0 10px 10px 0;
        padding: 3px 3px 1px 3px;
    }

    .jvectormap-legright-cnt-h .jvectormap-legright .jvectormap-legright-tick {
        float: left;
    }

    .jvectormap-legright-cnt-v .jvectormap-legright {
        margin: 10px 10px 0 0;
    }

    /**widgets*/

    .widget-line-list li {
        padding: 5px 20px 0 15px;
    }

    /*----Profile----*/


    /*-----Icon input-----*/

    // .input-icon .form-control:not(:last-child),
    // .input-icon .form-control:not(:first-child) {
    //     padding-left: 2.5rem;
    //     padding-right: inherit;
    // }

    .input-icon-addon {
        left: 0;
        right: inherit;
    }

    .input-icon-addon:last-child {
        left: auto;
        right: inherit;
    }


    .state-valid {
        padding-right: 2rem;
        padding-left: inherit;
    }

    .state-invalid {
        padding-right: 2rem;
        padding-left: inherit;
    }

    .map-content,
    .chart-content {
        left: 0;
        right: 0;
    }

    .map-header:before {
        left: 0;
        right: 0;
    }

    .status-icon {
        margin-right: .375rem;
        margin-left: inherit;
    }

    .chart-circle-value {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .chips {
        margin: 0 0 -.5rem;
    }

    .team i {
        margin-left: 10px;
        margin-right: inherit;
        float: right;
    }

    .chips .chip {
        margin: 0 .5rem .5rem 0;
    }


    .chip .avatar {
        float: left;
        margin: 0 .5rem 0 -.75rem;
    }

    .chat-line {
        text-align: right;
    }

    .chat-message {
        text-align: left;
        margin: 0 .5rem 0 2.5rem;
    }

    .chat-message:after {
        right: -5px;
        left: inherit;
        border-left: 6px solid #467fcf;
        border-top: 6px solid transparent;
    }

    .chat-line-friright .chat-message {
        margin-left: .5rem;
        margin-right: 2.5rem;
    }

    .chat-line-friright .chat-message:after {
        right: auto;
        left: -5px;
        border-left-width: 0;
        border-right: 5px solid #f3f3f3;
    }

    .example {
        border-radius: 3px 3px 0 0;
    }

    .example+.highlight {
        border-radius: 0 0 3px 3px;
    }

    .highlight {
        margin: 0rem 0 2rem;
    }


    /*-----Back to Top-----*/
    #back-to-top {
        left: 20px;
        right: inherit;
    }

    .tabs-menu ul li a {
        padding: 10px 20px 11px 20px;
    }

    .tabs-menu1 ul li a {
        padding: 10px 20px 11px 20px;
    }

    .tabs-menu-body {
        border-radius: 0 0 5px 5px;
    }

    .tab-menu-heading {
        padding: 20px 0px 20px 0px;
        border-radius: 5px 5px 0 0;
    }

    .item-card .cardbody {
        border-radius: 0 0 .2rem .2rem;
    }

    .item-card .cardprice {
        right: 0;
        left: inherit;
    }

    .receipts-inline-table .tabs-menu1 ul li .active {
        border-radius: 4px 4px 0 0;
    }

    /* ###### forms ####### */
    /*----- Custom control -----*/
    .card-custom-icon {
        left: 25px;
        right: inherit;
    }

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-right: 1rem;
        margin-left: inherit;
    }


    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .custom-control-label::before {
        right: 0;
        left: inherit;
    }

    .custom-control-label::after {
        right: 0;
        left: inherit;
    }

    .custom-control-label-md {
        padding-right: 15px;
        padding-left: inherit;
    }

    .custom-control-label-lg {
        padding-right: 15px;
        padding-left: inherit;
    }

    .custom-select {
        padding: 0.5rem 1.75rem 0.5rem 0.75rem;
    }


    .custom-select[multiple],
    .custom-select[size]:not([size="1"]) {
        padding-right: 0.75rem;
        padding-left: inherit;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0.5em;
        margin-right: inherit;
    }

    .custom-file-input:focus~.custom-file-label {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .custom-file-label {
        right: 0;
        left: 0;
    }

    .custom-file-label::after {
        right: 0;
        left: inherit;
        border-radius: 0 3px 3px 0;
    }

    .custom-range::-webkit-slider-thumb:focus {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .custom-range::-moz-range-thumb:focus {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .custom-range::-ms-thumb:focus {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .form-label-small {
        float: right;
    }

    .input-group-prepend>.input-group-text {
        border-left: 0;
    }

    .input-group-append>.input-group-text {
        border-right: 0;
    }

    .custom-range {
        padding-left: 0;
        padding-right: inherit;
    }

    .custom-range::-ms-fill-upper {
        margin-right: 15px;
        margin-left: inherit;
    }

    /*----- Date Picker ----*/
    .ui-datepicker {
        margin: 0px 0 0;
        box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
    }

    .ui-datepicker .ui-datepicker-header {
        padding: 0 0 5px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
        right: 5px;
        left: inherit;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
        left: 5px;
        right: inherit;
    }

    .ui-datepicker .ui-datepicker-calender {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .ui-datepicker .ui-datepicker-calender td {
        text-align: right;
    }

    .ui-datepicker .ui-datepicker-calender td:last-child {
        border-right: 0;
    }

    .ui-datepicker .ui-datepicker-calender td a:hover {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .ui-datepicker .ui-datepicker-calender .ui-datepicker-today a {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
    }

    .ui-datepicker-multi .ui-datepicker-group {
        padding-right: 15px;
        padding-left: inherit;
        float: left;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
        left: 10px;
        right: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
        right: 10px;
        left: inherit;
    }

    .ui-datepicker-multi .ui-datepicker-group-last {
        padding-right: 0;
        padding-left: inherit;
    }

    .custom-checkbox:before {
        right: 0;
        left: inherit;
    }

    .custom-checkbox:after {
        right: 0;
        left: inherit;
    }

    .form-control.fc-datepicker {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    /*------- Forms -------*/

    .select2-container .select2-selection--single .select2-selection__rrightered {
        padding-left: 20px !important;
        padding-right: 20px;
    }

    .form-control-plaintext.form-control-sm {
        padding-right: 0;
        padding-left: 0;
    }

    .input-group-sm>.form-control-plaintext.form-control,
    .input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
    .input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
    .input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
    .input-group-sm>.input-group-append>.form-control-plaintext.btn {
        padding-right: 0;
        padding-left: 0;
    }

    .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0;
    }

    .input-group-lg>.form-control-plaintext.form-control,
    .input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
    .input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
    .input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
    .input-group-lg>.input-group-append>.form-control-plaintext.btn {
        padding-right: 0;
        padding-left: 0;
    }

    .form-row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .form-row>.col,
    .form-row>[class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }

    .custom-control-input {
        margin-left: -1.25rem;
        right: 0;
    }

    .custom-control-inline {
        padding-left: 0;
        margin-right: 0.75rem;
    }

    .custom-control-inline .custom-control-input {
        margin-right: 0.3125rem;
        margin-left: 0;
    }

    .was-validated .form-control:valid:focus,
    .form-control.is-valid:focus,
    .was-validated .custom-select:valid:focus,
    .custom-select.is-valid:focus {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
    }

    .was-validated .custom-control-input:valid:focus~.custom-control-label::before,
    .custom-control-input.is-valid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(94, 186, 0, 0.25);
    }

    .was-validated .custom-file-input:valid:focus~.custom-file-label,
    .custom-file-input.is-valid:focus~.custom-file-label {
        box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus,
    .was-validated .custom-select:invalid:focus,
    .custom-select.is-invalid:focus {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
    }

    .was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
    .custom-control-input.is-invalid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #f0f0f2, 0 0 0 2px rgba(205, 32, 31, 0.25);
    }

    .was-validated .custom-file-input:invalid:focus~.custom-file-label,
    .custom-file-input.is-invalid:focus~.custom-file-label {
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
    }

    @media (min-width: 576px) {

        .form-inline .form-check {
            padding-left: 0;
            padding-right: inherit;
        }

        .form-inline .custom-control-input {
            margin-right: 0.25rem;
            margin-left: 0;
        }
    }

    /*-- Select2 Styles --*/
    .select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow b {
        left: 60%;
        right: inherit;
    }

    select.form-control.select-sm {
        padding: 0px 10px 1px 10px !important;
    }

    /*--- WIZARD ELEMENTS ---*/

    .wizard>.steps>ul li .title {
        margin-left: 5px;
        margin-right: inherit;
    }

    .wizard>.steps>ul li+li {
        margin-left: 5px;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .wizard>.steps>ul li+li {
            margin-left: 20px;
            margin-right: inherit;
        }

        .wizard>.steps>ul li .title {
            margin-right: 10px;
            margin-left: inherit;
        }

        .wizard.vertical>.actions {
            float: left;
            border-right: 1px solid #e9edf4;
        }

        .wizard.vertical>.content {
            float: right;
            border-right: 1px solid #e9edf4;
        }

        .wizard.vertical>.steps {
            float: right;
        }

        .wizard.vertical>.steps ul li+li {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .wizard>.steps>ul li+li {
            margin-right: 30px;
            margin-left: inherit;
        }
    }

    .wizard-style-1>.steps>ul a .title,
    .wizard-style-1>.steps>ul a:hover .title,
    .wizard-style-1>.steps>ul a:active .title {
        margin-right: 20px;
        margin-left: 20px;
    }

    .wizard-style-2>.steps>ul a .title,
    .wizard-style-2>.steps>ul a:hover .title,
    .wizard-style-2>.steps>ul a:active .title {
        margin-right: 20px;
        margin-left: inherit;
    }

    /*--- WIZARD ELEMENTS ---*/

    .input-group-addon {
        border-right: 0;
        border-radius: .25rem 0 0 .2rem;
    }

    .input-group>.form-control+.form-control,
    .input-group>.form-control+.custom-select,
    .input-group>.form-control+.custom-file {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group>.custom-select+.form-control,
    .input-group>.custom-select+.custom-select,
    .input-group>.custom-select+.custom-file {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group>.custom-file+.form-control,
    .input-group>.custom-file+.custom-select,
    .input-group>.custom-file+.custom-file {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group>.form-control:not(:last-child),
    .input-group>.custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.form-control:not(:first-child),
    .input-group>.custom-select:not(:first-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.custom-file:not(:last-child) .custom-file-label {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.custom-file:not(:first-child) .custom-file-label::after {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group-prepend .btn+.btn,
    .input-group-prepend .btn+.input-group-text {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-prepend .input-group-text+.input-group-text,
    .input-group-prepend .input-group-text+.btn {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-append .btn+.btn,
    .input-group-append .btn+.input-group-text {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-append .input-group-text+.input-group-text,
    .input-group-append .input-group-text+.btn {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group-prepend {
        margin-right: -1px;
        margin-left: inherit;
    }

    .input-group-append {
        margin-left: -1px;
        margin-right: inherit;
    }

    .input-group>.input-group-prepend>.btn,
    .input-group>.input-group-prepend>.input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-append:not(:last-child)>.btn,
    .input-group>.input-group-append:not(:last-child)>.input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-append>.btn,
    .input-group>.input-group-append>.input-group-text {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }

    .input-group>.input-group-prepend:not(:first-child)>.btn,
    .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .selectgroup-item+.selectgroup-item {
        margin-left: -1px;
        margin-right: inherit;
    }

    .selectgroup-item:not(:first-child) .selectgroup-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .selectgroup-item:not(:last-child) .selectgroup-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .selectgroup-input {
        left: 0;
        right: inherit;
    }

    .selectgroup-button-icon {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .selectgroup-pills {
        -ms-flex-align: left;
        align-items: flex-left;
    }

    .custom-switch {
        padding-left: 0;
        padding-right: inherit;
    }

    .custom-switch-indicator:before {
        left: 1px;
        right: inherit;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .custom-switch-input:checked~.custom-switch-indicator:before {
        left: calc(1rem + 1px);
        right: inherit;
    }


    .custom-switch-indicator-lg:before {
        left: 1px;
        right: inherit;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .custom-switch-input:checked~.custom-switch-indicator-lg:before {
        left: calc(1.46rem + 1px);
        right: inherit;
    }

    .custom-switch-indicator-xl:before {
        left: 1px;
        right: inherit;
        transition: .3s left;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .custom-switch-description {
        margin-right: .5rem;
        margin-left: inherit;
    }

    .material-switch>label::before {
        box-shadow: inset 0px 0px 10px #6574cd;
    }

    .material-switch>label::after {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        left: -4px;
        right: inherit;
    }

    .material-switch>input[type="checkbox"]:checked+label::after {
        left: 20px;
        right: inherit;
    }

    .imagecheck-input:focus~.imagecheck-figure {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .imagecheck-figure:before {
        left: .25rem;
        right: inherit;
    }

    .imagecheck-image:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    .imagecheck-image:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .colorinput-color:before {
        left: .25rem;
        right: inherit;
    }

    .colorinput-input:focus~.colorinput-color {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    /*-----selectize ------*/
    .selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
        box-shadow: inset 0 0 12px 4px #fff;
    }

    .selectize-control.plugin-drag_drop .ui-sortable-helper {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .selectize-dropdown-header {
        border-radius: 3px 3px 0 0;
    }

    .selectize-dropdown-header-close {
        right: 8px;
        left: inherit;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-right: 1px solid #f2f2f2;
        float: left;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
        border-right: 0 none;
        border-left: inherit;
    }

    .selectize-control.plugin-remove_button [data-value] {
        padding-right: 24px !important;
        padding-left: inherit !important;
    }

    .selectize-control.plugin-remove_button [data-value] .remove {
        right: 0;
        left: inherit;
        padding: 2px 0 0 0;
        border-radius: 0 2px 2px 0;
    }

    .selectize-control.plugin-remove_button [data-value].active .remove {
        border-left-color: #cacaca;
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-left-color: #fff;
    }

    .selectize-control.plugin-remove_button .remove-single {
        right: 28px;
        left: inherit;
    }

    .selectize-control.multi .selectize-input.has-items {
        padding: 7px 0.75rem 4px 7px;
    }

    .selectize-input.focus {
        box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
    }

    .selectize-input.dropdown-active {
        border-radius: 3px 3px 0 0;
    }

    .selectize-control.multi .selectize-input>div {
        margin: 0 3px 3px 0;
    }

    .selectize-input::after {
        clear: left;
    }

    .selectize-input.dropdown-active::before {
        left: 0;
        right: 0;
    }

    .selectize-dropdown {
        margin: -1px 0 0 0;
        border-radius: 0 0 3px 3px;
    }

    .selectize-control.single .selectize-input:after {
        right: 12px;
        left: inherit;
    }

    .selectize-dropdown .image,
    .selectize-input .image {
        margin: -1px .5rem -1px -4px;
        float: left;
    }

    .selectize-dropdown .image img {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .selectize-input .image {
        margin: -3px .75rem -3px -5px;
    }

    .selectize-input .image img {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    /* ###### layouts ####### */
    /*------ Alignments -------*/

    .border-end {
        border-left: 1px solid #e6ebf1 !important;
    }

    .border-start {
        border-right: 1px solid #e6ebf1 !important;
    }


    .border-start-right {
        border-right: 1px solid #e6ebf1 !important;
        border-left: 1px solid #e6ebf1 !important;
    }

    .border-start-0 {
        border-right: 0 !important;
    }

    .border-start-right-0 {
        border-right: 0px solid #e6ebf1 !important;
        border-left: 0px solid #e6ebf1 !important;
    }

    .rounded-top {
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
    }

    .rounded-end {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    .rounded-bottom {
        border-bottom-right-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .rounded-start {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        left: 0;
        right: inherit;
    }

    .justify-content-left {
        -ms-flex-pack: left !important;
        justify-content: flex-left !important;
    }

    .justify-content-right {
        -ms-flex-pack: right !important;
        justify-content: flex-right !important;
    }

    .align-items-left {
        -ms-flex-align: left !important;
        align-items: flex-left !important;
    }

    .align-items-right {
        -ms-flex-align: right !important;
        align-items: flex-right !important;
    }

    .align-content-left {
        -ms-flex-line-pack: left !important;
        align-content: flex-left !important;
    }

    .align-content-right {
        -ms-flex-line-pack: right !important;
        align-content: flex-right !important;
    }

    .align-self-left {
        -ms-flex-item-align: left !important;
        align-self: flex-left !important;
    }

    .align-self-right {
        -ms-flex-item-align: right !important;
        align-self: flex-right !important;
    }

    @media (min-width: 576px) {
        .float-sm-left {
            float: left !important;
        }

        .float-sm-right {
            float: right !important;
        }

        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-sm-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-sm-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-sm-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-sm-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-sm-8 {
            margin-right: 4rem !important;
            margin-left: inherit;
        }

        .ms-sm-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pt-sm-1 {
            padding-top: 0.25rem !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }


        .pe-sm-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-sm-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-sm-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }

        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .text-sm-left {
            text-align: left !important;
        }

        .text-sm-right {
            text-align: right !important;
        }

        .justify-content-sm-left {
            -ms-flex-pack: left !important;
            justify-content: flex-left !important;
        }

        .justify-content-sm-right {
            -ms-flex-pack: right !important;
            justify-content: flex-right !important;
        }

        .align-items-sm-left {
            -ms-flex-align: left !important;
            align-items: flex-left !important;
        }

        .align-items-sm-right {
            -ms-flex-align: right !important;
            align-items: flex-right !important;
        }

        .align-content-sm-left {
            -ms-flex-line-pack: left !important;
            align-content: flex-left !important;
        }

        .align-content-sm-right {
            -ms-flex-line-pack: right !important;
            align-content: flex-right !important;
        }

        .align-self-sm-left {
            -ms-flex-item-align: left !important;
            align-self: flex-left !important;
        }

        .align-self-sm-right {
            -ms-flex-item-align: right !important;
            align-self: flex-right !important;
        }
    }

    @media (min-width: 768px) {

        .text-md-left {
            text-align: left !important;
        }

        .text-md-right {
            text-align: right !important;
        }

        .float-md-left {
            float: left !important;
        }

        .float-md-right {
            float: right !important;
        }

        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-md-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-md-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-md-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-md-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-md-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-md-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-md-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-md-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-md-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-md-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-md-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-md-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-md-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .ps-md-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-md-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-md-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-md-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-md-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-md-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-md-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-md-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-md-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-md-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-md-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-md-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-md-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }

        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .justify-content-md-left {
            -ms-flex-pack: left !important;
            justify-content: flex-left !important;
        }

        .justify-content-md-right {
            -ms-flex-pack: right !important;
            justify-content: flex-right !important;
        }

        .align-items-md-left {
            -ms-flex-align: left !important;
            align-items: flex-left !important;
        }

        .align-items-md-right {
            -ms-flex-align: right !important;
            align-items: flex-right !important;
        }

        .align-content-md-left {
            -ms-flex-line-pack: left !important;
            align-content: flex-left !important;
        }

        .align-content-md-right {
            -ms-flex-line-pack: right !important;
            align-content: flex-right !important;
        }

        .align-self-md-left {
            -ms-flex-item-align: left !important;
            align-self: flex-left !important;
        }

        .align-self-md-right {
            -ms-flex-item-align: right !important;
            align-self: flex-right !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-left {
            text-align: left !important;
        }

        .text-lg-right {
            text-align: right !important;
        }

        .float-lg-left {
            float: left !important;
        }

        .float-lg-right {
            float: right !important;
        }

        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }


        .me-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-lg-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-lg-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-lg-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }

        .me-lg-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-lg-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-lg-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-lg-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-lg-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }

        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .justify-content-lg-left {
            -ms-flex-pack: left !important;
            justify-content: flex-left !important;
        }

        .justify-content-lg-right {
            -ms-flex-pack: right !important;
            justify-content: flex-right !important;
        }

        .align-items-lg-left {
            -ms-flex-align: left !important;
            align-items: flex-left !important;
        }

        .align-items-lg-right {
            -ms-flex-align: right !important;
            align-items: flex-right !important;
        }

        .align-content-lg-left {
            -ms-flex-line-pack: left !important;
            align-content: flex-left !important;
        }

        .align-content-lg-right {
            -ms-flex-line-pack: right !important;
            align-content: flex-right !important;
        }

        .align-self-lg-left {
            -ms-flex-item-align: left !important;
            align-self: flex-left !important;
        }

        .align-self-lg-right {
            -ms-flex-item-align: right !important;
            align-self: flex-right !important;
        }
    }

    @media (min-width: 1280px) {
        .text-xl-left {
            text-align: left !important;
        }

        .text-xl-right {
            text-align: left !important;
        }

        .float-xl-left {
            float: left !important;
        }

        .float-xl-right {
            float: right !important;
        }

        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }

        .me-xl-4 {
            margin-left: 1rem !important;
            margin-left: inherit !important;
        }

        .ms-xl-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }

        .me-xl-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }

        .me-xl-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }

        .me-xl-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-7 {
            margin-right: 3rem !important;
        }

        .me-xl-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }

        .me-xl-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }

        .ms-xl-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }

        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }

        .pe-xl-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }

        .ps-xl-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }

        .me-xl-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }

        .justify-content-xl-left {
            -ms-flex-pack: left !important;
            justify-content: flex-left !important;
        }

        .justify-content-xl-right {
            -ms-flex-pack: right !important;
            justify-content: flex-right !important;
        }

        .align-items-xl-left {
            -ms-flex-align: left !important;
            align-items: flex-left !important;
        }

        .align-items-xl-right {
            -ms-flex-align: right !important;
            align-items: flex-right !important;
        }

        .align-content-xl-left {
            -ms-flex-line-pack: left !important;
            align-content: flex-left !important;
        }

        .align-content-xl-right {
            -ms-flex-line-pack: right !important;
            align-content: flex-right !important;
        }

        .align-self-xl-left {
            -ms-flex-item-align: left !important;
            align-self: flex-left !important;
        }

        .align-self-xl-right {
            -ms-flex-item-align: right !important;
            align-self: flex-right !important;
        }
    }

    .float-start {
        float: left !important;
    }

    .float-end {
        float: left !important;
    }

    .float-start {
        float: right !important;
    }

    .fixed-top {
        right: 0;
        left: 0;
    }

    .fixed-bottom {
        right: 0;
        left: 0;
    }

    .shadow-sm {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .shadow-lg {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }


    .me-0 {
        margin-left: 0 !important;
        margin-right: inherit !important;
    }


    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }


    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: inherit !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: inherit !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
    }

    .me-3 {
        margin-left: 0.75rem !important;
        margin-right: inherit !important;
    }

    .ms-3 {
        margin-right: 0.75rem !important;
        margin-left: inherit !important;
    }

    .me-4 {
        margin-left: 1rem !important;
        margin-right: inherit !important;
    }

    .ms-4 {
        margin-right: 1rem !important;
    }

    .me-5 {
        margin-left: 1.5rem !important;
        margin-right: inherit !important;
    }

    .ms-5 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .me-6 {
        margin-left: 2rem !important;
        margin-right: inherit !important;
    }

    .ms-6 {
        margin-right: 2rem !important;
        margin-left: inherit !important;
    }

    .me-7 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .ms-7 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .me-8 {
        margin-left: 4rem !important;
        margin-right: inherit !important;
    }

    .ms-8 {
        margin-right: 4rem !important;
        margin-left: inherit !important;
    }

    .me-9 {
        margin-left: 6rem !important;
        margin-right: inherit !important;
    }

    .ms-9 {
        margin-right: 6rem !important;
        margin-left: inherit !important;
    }

    .pe-0 {
        padding-left: 0 !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }

    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: inherit !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }

    .pe-3 {
        padding-left: 0.75rem !important;
    }


    .ps-3 {
        padding-right: 0.75rem !important;
        padding-left: inherit !important;
    }

    .pe-4 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }

    .ps-4 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }

    .pe-5 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }

    .ps-5 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }

    .pe-6 {
        padding-left: 2rem !important;
        padding-right: inherit !important;
    }

    .ps-6 {
        padding-right: 2rem !important;
        padding-left: inherit !important;
    }

    .pe-7 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }

    .ps-7 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }

    .pe-8 {
        padding-left: 4rem !important;
        padding-right: inherit !important;
    }

    .ps-8 {
        padding-right: 4rem !important;
        padding-left: inherit !important;
    }

    .pe-9 {
        padding-left: 6rem !important;
        padding-right: inherit !important;
    }

    .ps-9 {
        padding-right: 6rem !important;
        padding-left: inherit !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    /*------- Alignments & values-------*/

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    blockquote {
        padding-left: 2rem;
        border-left: 2px solid rgba(0, 40, 100, 0.12);
    }

    blockquote cite {
        text-align: right;
    }

    .gutters-0 {
        margin-right: 0;
        margin-left: 0;
    }

    .gutters-0>.col,
    .gutters-0>[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .gutters-xs {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }

    .gutters-xs>.col,
    .gutters-xs>[class*="col-"] {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
    }

    .gutters-sm {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }

    .gutters-sm>.col,
    .gutters-sm>[class*="col-"] {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .gutters-lg {
        margin-right: -1rem;
        margin-left: -1rem;
    }

    .gutters-lg>.col,
    .gutters-lg>[class*="col-"] {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .gutters-xl {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
    }

    .gutters-xl>.col,
    .gutters-xl>[class*="col-"] {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    @media (min-width: 1600px) {
        body.aside-opened .page {
            margin-right: 22rem;
            margin-left: inherit;
        }
    }

    .pos-absolute {
        left: 0;
        right: 0;
    }

    /*------ Background colors -------*/

    .shadow {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    }

    .shadow2 {
        box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0), 10px 10px 15px -5px #b0b8d6 !important;
    }


    /*------ Default -------*/
    .card {
        box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03);
    }

    .card>hr {
        margin-right: 0;
        margin-left: 0;
    }

    .card>.list-group:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .card>.list-group:last-child .list-group-item:last-child {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
    }

    .card-header-tabs {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }

    .card-img-overlay {
        right: 0;
        left: 0;
    }

    .card-img-top {
        border-top-left-radius: calc(3px - 1px);
        border-top-right-radius: calc(3px - 1px);
    }

    .card-img-bottom {
        width: 100%;
        border-bottom-right-radius: calc(3px - 1px);
        border-bottom-left-radius: calc(3px - 1px);
    }

    @media (min-width: 576px) {

        .card-deck {
            margin-right: -0.75rem;
            margin-left: -0.75rem;
        }

        .card-deck .card {
            margin-right: 0.75rem;
            margin-left: 0.75rem;
        }

        .card-group>.card+.card {
            margin-left: 0;
            border-left: 0;
        }

        .card-group>.card:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .card-group>.card:first-child .card-img-top,
        .card-group>.card:first-child .card-header {
            border-top-right-radius: 0;
        }

        .card-group>.card:first-child .card-img-bottom,
        .card-group>.card:first-child .card-footer {
            border-bottom-right-radius: 0;
        }

        .card-group>.card:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .card-group>.card:last-child .card-img-top,
        .card-group>.card:last-child .card-header {
            border-top-left-radius: 0;
        }

        .card-group>.card:last-child .card-img-bottom,
        .card-group>.card:last-child .card-footer {
            border-bottom-left-radius: 0;
        }

        .card-group>.card:only-child .card-img-top,
        .card-group>.card:only-child .card-header {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        .card-group>.card:only-child .card-img-bottom,
        .card-group>.card:only-child .card-footer {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }


    /*------ Default Card Values -------*/

    .reg {
        float: right;
    }

    .card-table tr td:first-child,
    .card-table tr th:first-child {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .card-table tr td:last-child,
    .card-table tr th:last-child {
        padding-left: 1.5rem;
    }

    .card-profile-img {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .card-link+.card-link {
        margin-left: 1rem;
        margin-left: 1.5rem;
    }

    .card-list-group .list-group-item {
        border-right: 0;
        border-left: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .card-header-pills {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }

    .list-media .media-img {
        float: left;
        margin-right: 20px;
        margin-left: inherit;
    }

    .list-media .info {
        padding-left: 55px;
        padding-right: inherit;
    }

    .list-media .info .text-right {
        right: 0;
        left: inherit;
    }

    .card-options {
        margin-right: auto;
        margin-left: -.5rem;
    }

    .card-options a.option-dots {
        box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
        margin-right: 5px;
        margin-left: inherit;
    }

    .widgets-cards .wrp.icon-circle {
        margin-right: 15px;
        margin-left: inherit;
    }


    ul li .legright-dots {
        margin-right: .5rem;
        margin-left: inherit;
    }

    .legright li {
        float: left;
    }

    .card-img-absolute {
        right: 0;
        left: inherit;
    }

    .statistics-box {
        padding-left: 80px;
        padding-right: inherit;
        text-align: right;
    }

    .statistics-box .ico {
        left: 0px;
        right: inherit;
    }

    /**Card status*/
    .card-status {
        left: 0px;
        right: 0px;
    }

    .card-status-left {
        right: auto;
        left: inherit;
    }

    /**Card fullscreen*/
    .card-fullscreen {
        left: 0;
        right: 0;
    }

    /**Card alert*/
    .card-alert {
        margin: -1px -1px 0;
    }

    .card-category {
        margin: 0 0 .5rem;
    }

    .list-card {
        -webkit-box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
        box-shadow: 0 2px 10px 0 rgba(4, 4, 7, 0.1);
    }

    .header-search .search-element {
        margin-left: 15px;
        margin-right: inherit;
    }

    .header-search .btn {
        left: 0;
        right: inherit;
    }

    .hor-header {
        box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    }

    /* ================== Desktop Main Menu CSS ================== */

    .desktoplogo {
        float: left;
    }

    .horizontalMenu>.horizontalMenu-list {
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li {
        float: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>a {
        padding: 0px 0px 0px 12px;
    }

    .horizontalMenu>.horizontalMenu-list>li:last-child>a {
        border-right: 0px;
        margin-right: 0;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li:first-child>a {
        margin-left: 0;
        margin-right: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
        padding-left: 25px;
        padding-right: 25px;
    }

    .horizontalMenu>.horizontalMenu-list>li>a i.horizontal-icon {
        margin-right: 3px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>a .arrow:after {
        border-left: 4px solid rgba(0, 0, 0, 0);
        border-right: 4px solid rgba(0, 0, 0, 0);
        float: right;
        right: 11px;
        margin: 0 0 0 14px;
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li.rightmenu {
        float: right;
        float: right;
    }

    .horizontalMenu>.horizontalMenu-list>li.rightmenu a {
        padding: 0px 30px 0px 20px;
    }

    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch {
        float: right;
        margin: 16px 0px 0px 0px;
    }

    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch input {
        float: right;
        right: 0;
    }

    .horizontalMenu>.horizontalMenu-list>li.rightmenu>.topmenusearch .btnstyle {
        right: 0;
        left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
        padding: 10px 15px 10px 0px;
        box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
        border-right: 0 none;
        text-align: right;
        border-right: 0px solid;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a>i {
        margin-right: 9px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
        right: 100%;
        left: inherit;
        border-radius: 0 4px 4px 0;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
        border-right: 0 none;
        text-align: right;
        border-right: 0px solid;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
        margin-right: 9px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
        right: 115%;
        left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
        border-right: 0 none;
        text-align: right;
        border-right: 0px solid;
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a>i {
        margin-right: 9px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu {
        left: 0px;
        right: inherit;
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .megamenu-content {
        box-shadow: 0px 5px 25px rgba(227, 228, 238, 0.2);
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
        padding: 5px 5px 5px 0px;
        margin: 0px 0px 4px 0px;
        text-align: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li {
        text-align: right;
        padding: 5px 30px 5px 10px;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
        text-align: right;
        border-right: 0 none;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu li i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
        right: auto;
        left: auto;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfdiv {
        right: auto;
        left: auto;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="submit"] {
        float: right;
        margin-right: 15px;
        margin-left: inherit;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form input[type="button"] {
        float: right;
    }

    .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .carousel-caption {
        left: 0;
        right: 0;
    }

    /*==============================================================================
								  Start Mobile CSS
	===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    @media only screen and (max-width: 991px) {


        .horizontalMenu {
            left: 0;
            right: inherit;
            overflow-y: hidden;
        }

        .horizontalMenu>.horizontalMenu-list {
            margin-left: -240px;
            margin-right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li {
            border-right: none;
            clear: right;
        }

        .horizontalMenu>.horizontalMenu-list>li>a {
            padding: 2px 0px 2px 32px;
            text-align: right;
            border-right: solid 0px;
        }

        .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
            padding-left: 17px;
            padding-right: 17px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a {
            padding: 10px 0px 10px 30px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li span+a {
            padding-right: 30px;
            padding-left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu {
            left: 100%;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li {
            margin: 0px 0px 0px 0px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a {
            padding: 10px 0px 10px 34px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li span+a {
            padding-right: 30px;
            padding-left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu {
            left: 100%;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li {
            margin: 0px 0px 0px 0px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a {
            padding: 10px 0px 10px 34px;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li span+a {
            padding-right: 30px;
            padding-left: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu.halfmenu {
            padding: 5px 0px 10px 0px;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .title {
            padding: 10px 8px 10px 0px;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .menu_form {
            padding: 5px 0px 62px 0px;
        }

        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */
        .horizontal-header {
            left: 0;
            right: inherit;
        }

        /* Mobile Search Bar*/

        .callusbtn {
            right: 5px;
            left: inherit;
        }

        /* Mobile Toggle Menu icon (X ICON) */
        .animated-arrow {
            left: 0;
            right: inherit;
            padding: 16px 35px 16px 0px;
            margin: 6px 0 0 8px;
        }

        /* ================== Mobile Overlay/Drawer CSS ================== */
        .horizontal-overlapbg {
            left: 0;
            right: inherit;
        }

        .active .horizontalMenucontainer {
            margin-right: 240px;
            margin-left: inherit;
        }

        .active .horizontalMenu {
            overflow-y: scroll;
        }

        .active .horizontalMenu>.horizontalMenu-list {
            margin-right: 0;
            margin-left: inherit;
        }

        /* ================== Mobile Sub Menu Expander Arrows  ================== */
        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click {
            left: 0;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i {
            margin-left: 10px;
            margin-right: inherit;
            float: left;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02 {
            left: 0;
            right: inherit;
        }

        .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i {
            margin: 8px 6px 8px 6px;
            float: left;
        }

        .hor-menu .horizontalMenu>.horizontalMenu-list>li:last-child>a {
            margin-right: 5px !important;
            margin-left: inherit;
        }

        /*End Media Query*/
    }

    /* Extra @Media Query*/
    @media only screen and (min-width: 992px) and (max-width: 1162px) {
        .horizontalMenu>.horizontalMenu-list>li>a {
            padding-left: 5px;
            padding-right: 5px;
        }

        .horizontalMenu>.horizontalMenu-list>li a.menuhomeicon {
            padding-left: 22px;
            padding-right: 22px;
        }
    }

    .sticky.sticky-pin .horizontal-main {
        box-shadow: -6px 5px 8px 0 rgba(24, 23, 38, 0.06);
    }

    .mega-menubg {
        box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
    }

    .horizontal-main.hor-menu {
        box-shadow: 5px 7px 26px -5px rgba(24, 23, 38, 0.06);
    }

    .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu .sub-menu-sub:after {
        left: 14px;
        right: inherit;
    }

    .hor-menu .horizontalMenu>.horizontalMenu-list>li:first-child>a {
        margin-left: 0;
        margin-right: inherit;
    }

    .hor-menu .horizontalMenu>.horizontalMenu-list>li:last-child>a {
        margin-right: 0;
        margin-left: inherit;
    }

    .hor-icon {
        margin-right: 5px;
        margin-left: inherit;
    }

    @media (min-width: 768px) {
        .hor-header .header-brand {
            margin-left: -15px;
            margin-right: inherit;
        }
    }

    @media (max-width: 767px) {

        .hor-header .header-brand .header-brand-img {
            margin: 0 auto !important;
            margin-top: 3px !important;
        }

        .hor-header .animated-arrow.hor-toggle {
            left: 6px;
            right: inherit;
            padding: 16px 0px 16px 35px !important;
        }

        .responsive-navbar.navbar .navbar-collapse {
            right: 0px;
            left: inherit;
            box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
        }

        .hor-header .header-brand .header-brand-img {
            left: 0;
            right: 0;
        }
    }

    .hor-header .header-brand {
        margin-right: 0rem;
        margin-left: inherit;
    }

    @media (min-width: 767px) {

        .hor-header .form-inline .btn {
            padding: 4px 10px 4px 10px;
            right: inherit !important;
            left: 0 !important;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        .hor-header .form-inline .form-control {
            padding-right: 14px;
            padding-left: inherit;
        }
    }

    @media (min-width: 992px) {
        .hor-header .demo-icon {
            border-left: 1px solid #e6ebf1 !important;
        }

        .hor-header .theme-layout {
            border-left: 1px solid #e6ebf1;
        }
    }

    .cookie-popup-learn-more {
        margin-right: 5px;
        margin-left: inherit;
    }

    .cookie-popup-right {
        text-align: left;
    }

    .row {
        margin-right: -0.75rem;
        margin-left: -0.75rem;
    }

    .g-0 {
        margin-right: 0;
        margin-left: 0;
    }

    .g-0>.col,
    .g-0>[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }

    .offset-1 {
        margin-left: 8.33333333%;
        margin-right: inherit;
    }

    .offset-2 {
        margin-left: 16.66666667%;
        margin-right: inherit;
    }

    .offset-3 {
        margin-left: 25%;
        margin-right: inherit;
    }

    .offset-4 {
        margin-left: 33.33333333%;
        margin-right: inherit;
    }

    .offset-5 {
        margin-left: 41.66666667%;
        margin-right: inherit;
    }

    .offset-6 {
        margin-left: 50%;
        margin-right: inherit;
    }

    .offset-7 {
        margin-left: 58.33333333%;
        margin-right: inherit;
    }

    .offset-8 {
        margin-left: 66.66666667%;
        margin-right: inherit;
    }

    .offset-9 {
        margin-left: 75%;
        margin-right: inherit;
    }

    .offset-10 {
        margin-left: 83.33333333%;
        margin-right: inherit;
    }

    .offset-11 {
        margin-left: 91.66666667%;
        margin-right: inherit;
    }

    @media (min-width: 576px) {

        .offset-sm-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-sm-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-sm-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-sm-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-sm-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-sm-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-sm-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-sm-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-sm-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-sm-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-sm-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-sm-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 768px) {

        .offset-md-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-md-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-md-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-md-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-md-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-md-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-md-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-md-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-md-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-md-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-md-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-md-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {

        .offset-lg-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-lg-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-lg-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-lg-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-lg-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-lg-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-lg-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-lg-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-lg-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-lg-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-lg-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-lg-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 1280px) {

        .offset-xl-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-xl-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-xl-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-xl-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-xl-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-xl-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-xl-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-xl-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-xl-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-xl-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-xl-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-xl-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    /* ######  pages ###### */
    /*-----Gallery-----*/

    .demo-gallery>ul>li {
        float: left;
    }

    .demo-gallery>ul>li a {
        float: left;
    }

    .demo-gallery>ul>li a .demo-gallery-poster {
        left: 0;
        right: 0;
    }

    .demo-gallery>ul>li a .demo-gallery-poster>img {
        left: 50%;
        right: inherit;
        margin-left: -10px;
        margin-right: inherit;
    }

    .demo-gallery .justified-gallery>a .demo-gallery-poster {
        left: 0;
        right: 0;
    }

    .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
        left: 50%;
        right: inherit;
        margin-left: -10px;
        margin-right: inherit;
    }

    .demo-gallery .video .demo-gallery-poster img {
        margin-left: -24px;
        margin-right: inherit;
    }

    .gallery-list {
        margin: -5px -5px 15px;
    }

    .gallery-list>li {
        float: left;
    }

    .gallery-list>li .image {
        border-radius: 5px 5px 0 0;
    }

    .gallery-list>li .image img {
        left: 0;
        right: 0;
    }

    .gallery-list>li .btn-list {
        left: 10px;
        right: 10px;
    }

    .pricing1 .card-category {
        border-radius: 7px 7px 0 0;
    }


    .pricing-table:hover {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    }

    .pricing-table:hover>.panel-footer-landing {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
    }

    .panel-heading-landing {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .col-sm-4 .pricing-table .table td {
        text-align: left;
    }

    .panel-footer-landing {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .panel-heading {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .panel.price .list-group-item:last-child {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .panel.price .list-group-item:first-child {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .panel-footer {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .panel.price .btn {
        box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
    }

    /*right of pricing*/
    @media (min-width: 992px) {
        .plan-card.active {
            margin-left: -20px;
            margin-right: inherit;
            box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.16) !important;
        }

        .pricing-sctive {
            margin-left: -20px;
            margin-right: inherit;
        }

        .panel.price.active {
            margin-left: -20px;
            margin-right: inherit;
            box-shadow: 0px 10px 10px 20px rgba(179, 186, 212, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.08);
            border-right: 1px !important;
        }

        .g-0 .panel.price {
            border-right: 0;
        }

        .g-0 .panel.price:last-child {
            border-right: 1px;
        }
    }


    .price-svg .price-title {
        left: 0;
        right: 0;
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li:first-child a {
        border-radius: 0 50px 50px 0;
        border-left: 0;
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li:last-child a {
        border-radius: 50px 0 0 50px;
    }

    .pricing-tabs .pri-tabs-heading3 ul.nav-price li a {
        border-right: 1px solid #e6ebf1;
    }

    .profile-cover .cover .inner-cover .cover-menu-mobile {
        right: 10px;
        left: inherit;
    }

    .profile-cover .cover ul.cover-menu {
        padding-left: 150px;
        padding-right: inherit;
        left: 1px;
        right: inherit;
        float: left;
    }

    .profile-cover .cover ul.cover-menu li {
        float: left;
        margin-right: 0px;
        margin-left: inherit;
    }


    /*---End profile---*/
    .notifyimg {
        margin-left: 20px;
        margin-right: inherit;
    }

    @media (max-width: 767.98px) {
        .desktop-lgo {
            left: 0;
            right: 0;
            margin: 0 auto !important;
        }
    }

    @media (max-width: 576px) {
        .nav-link.icon.theme-layout.nav-link-bg.layout-setting {
            border-left: 1px solid #e6ebf1 !important;
        }
    }

    .main-msg-wrapper {
        margin-right: 10px;
    }

    .selectgroup-pills .selectgroup-item {
        margin-right: 0.5rem !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: 5px;
    }

    .sp-cancel {
        margin-left: 5px;
    }

    .input-group-prepend>.input-group-text {
        border-right: 1px solid #e6ebf1;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .ms-choice>div {
        left: 0 !important;
        right: inherit !important;
        margin-left: 10px;
    }

    .ms-choice>span {
        right: 0;
        left: inherit;
        padding-right: 18px;
    }

    .SumoSelect>.CaptionCont>label {
        left: 0px;
        right: inherit;
    }

    .SumoSelect>.optWrapper.multiple>.options li.opt {
        padding-right: 35px;
    }

    .SumoSelect>.optWrapper.multiple>.options li.opt span,
    .SumoSelect .select-all>span {
        margin-right: -35px;
    }

    .SumoSelect>.CaptionCont>span {
        padding-left: 30px !important;
        padding-right: 0px !important;
    }

    .checkbox-group label {
        padding-right: 25px;
    }

    .checkbox-group label:before {
        right: 0;
    }

    .transfer-double-list-search-input {
        padding: 5px 8px 4px 0px;
    }

    .transfer-double-selected-list-search-input {
        padding: 5px 8px 4px 0px;
    }

    .transfer-double-list-main .transfer-double-group-list-ul {
        padding-right: 7px;
    }

    .transfer-double-list-footer label {
        margin-right: 10px;
    }

    .transfer-double-list-main .transfer-double-group-list-ul .transfer-double-group-list-li .transfer-double-group-list-li-ul {
        padding-right: 20px;
    }

    .intl-tel-input .flag-dropdown .country-list .flag {
        margin-left: 6px;
    }

    .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .intl-tel-input .flag-dropdown .selected-flag .down-arrow {
        right: 20px;
    }

    .intl-tel-input .flag-dropdown .selected-flag {
        padding: 9px 16px 10px 24px !important;
    }

    .sw-theme-dots>ul.step-anchor>li>a:after {
        right: 38%;
    }

    .sw-theme-dots>ul.step-anchor>li>a:before {
        right: 37%;
    }

    .custom-control-label {
        text-align: right;
    }

    .acc-step-number {
        line-height: 22px;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
    }

    .richText .richText-toolbar ul li {
        float: right;
    }

    .richText-toolbar ul:first-child {
        border-right: 0px;
    }

    .ql-editor {
        text-align: right;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 6px;
        right: inherit;
    }

    .ql-toolbar.ql-snow .ql-picker-label {
        border-right: #e2e3e7 solid 1px;
    }

    .tree li i {
        margin-left: 10px;
        margin-right: inherit;
        float: right;
        margin-top: 5px;
    }

    .fe.fe-chevron-right {
        transform: rotate(180deg);
    }

    #chart2 {
        .apexcharts-yaxis.apexcharts-xaxis-inversed {
            text {
                transform: translateX(-75px);
            }
        }
    }

    #chart-timeline {
        .apexcharts-yaxis .apexcharts-yaxis-texts-g {
            text {
                transform: translateX(-35px);
            }
        }
    }

    #chart3 {
        .apexcharts-yaxis .apexcharts-yaxis-texts-g {
            text {
                transform: translateX(-25px);
            }
        }
    }

    #chart {
        .apexcharts-yaxis .apexcharts-yaxis-texts-g {
            text {
                transform: translateX(-15px);
            }
        }
    }

    #echart1 {
        span {
            margin-left: 5px !important;
        }
    }

    .c3-axis.c3-axis-y .tick {
        text {
            transform: translateX(-10px);
        }
    }

    #chart-bar-rotated,
    #chart-spline-rotated {
        .c3-axis.c3-axis-x .tick {
            text {
                transform: translateX(-20px);
            }
        }
    }

    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: -1px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &.register1 .input-group-text {
        border-right: 1px solid #e6ebf1 !important;
    }

    &.register-2 .input-group-text {
        border-right: 1px solid #e6ebf1 !important;
        border-left: 0 !important;
    }

    &.register1 div.br-tl-0 {
        border-top-right-radius: 0px !important;
    }

    &.register1 div.br-bl-0 {
        border-bottom-right-radius: 0px !important;
    }

    &.register-3 .input-group-text {
        border-left: 0 !important;
        border-right: 1px solid #755fce !important;
    }

    .chartjs-size-monitor-shrink {
        width: 100% !important;
    }

    .mail-inbox-elements span.badge {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .mail-inbox-elements .list-group-item i {
        margin-left: 1.5rem !important;
        margin-right: 0.5rem !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: 5px !important;
        left: inherit !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        margin-right: -4px !important;
        margin-left: inherit !important;
    }

    @media (max-width:767.98px) {
        div.dataTables_wrapper div.dataTables_filter {
            text-align: center !important;
        }
    }

    @media (max-width: 992px) {
        .dt-buttons.btn-group {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .legend {
        margin-left: 10px;
    }

    .bs-popover-end>.popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
        right: 0;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-left-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-end>.popover-arrow,
    .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
        right: calc((-17.1rem + 1px) * -1) !important;
        width: 0.5rem;
        height: 1rem;
        margin: 0.3rem 0;
    }

    .bs-popover-end>.popover-arrow::before {
        border-left-color: rgba(0, 0, 0, 0.25) !important;
        border-right-color: transparent !important;
    }

    .hor-header .profile-dropdown {
        padding: 0.95rem;
    }

    .header .profile-dropdown {
        border-left: 0px solid #e6ebf1 !important;
    }

    .header-notify .show.dropdown-menu {
        right: -198px !important;
        left: 0 !important;
    }

    .header-message .show.dropdown-menu {
        left: 0 !important;
    }

    @media (min-width:992px) and (max-width:1400px) {
        .hor-header .header-search {
            min-width: 14rem !important;
            border: 1px solid #e6ebf1 !important;
        }
    }

    @media (max-width: 991.98px) and (min-width: 768px) {
        .animated-arrow.hor-toggle {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .animated-arrow {
            right: 0;
            left: inherit;
            padding: 16px 0px 16px 35px;
            margin: 0px 8px 0 0;
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        .theme-layout {
            border-left: 1px solid #e6ebf1 !important;
        }
    }

    .hor-header .profile-dropdown {
        border-left: 1px solid #e6ebf1 !important;
    }

    @media (min-width: 992px) {
        .hor-header .demo-icon {
            border-right: 0px solid #e6ebf1 !important;
        }
    }
    @media (max-width: 767px) {
        .hor-header .animated-arrow.hor-toggle {
            right: 6px !important;
            left: inherit !important;
            padding: 16px 0px  16px 35px !important;
        }
    }
    @media only screen and (max-width: 991px) {
        &.active .horizontalMenucontainer {
            margin-right: 240px !important;
        }
    }
    @media (min-width: 767.98px) and (max-width: 992px) {
        .header .main-header-end .nav-link.icon {
            padding: 2.2rem 0.3rem !important;
        }
    }
    @media (max-width: 767.98px) {
        .hor-header .demo-icon .header-icon {
            margin-top: 0px !important;
        }
        .hor-header .main-header-end .icon.demo-icon {
            padding: 1rem 0.3rem !important;
        }
        .hor-header.header .main-header-end .nav-link.icon {
            padding: 1.8rem 0.3rem !important;
        }
        .hor-header .profile-dropdown {
            padding: 0.5rem;
        }
        .theme-layout.nav-link-bg.layout-setting {
            border-left: 1px solid #e6ebf1 !important;
        }
    }
    table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:after {
    left: 0.5em !important;
    right: inherit;
    content: "↓";
    }
    table.dataTable>thead .sorting:before, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_desc_disabled:before {
    left: 1em !important;
    right: inherit;
    content: "↑";
    }
    .header .profile-dropdown {
        border-left: 1px solid #e6ebf1 !important;
    }
}